import React, { ReactNode, FunctionComponent } from "react";

import { DropdownFilterWrapper } from "src/components/Dropdown/styled.dropdown";

type Props = {
  isOpen: boolean;
  height?: string;
  children: ReactNode;
};

const CustomDropdown: FunctionComponent<Props> = ({
  isOpen,
  height,
  children,
}) => {
  return (
    <div>
      {isOpen && (
        <DropdownFilterWrapper style={{ height }}>
          {children}
        </DropdownFilterWrapper>
      )}
    </div>
  );
};

export default CustomDropdown;
