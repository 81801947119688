import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

import { ReactComponent as CircleFill } from "src/assets/images/checkbox-circle-fill.svg";

const NotificationContent = ({
  title,
  message,
  isExport,
  handleClose,
}: {
  title?: "error" | "success" | "Message";
  message: string;
  isExport?: boolean;
  handleClose?: () => void;
}) => {
  let toastBg = "";
  let toastTitle = title;
  const showToast = message && title;

  if (title === "error") {
    toastBg = "danger";
  } else if (title === "success") {
    toastBg = "success";
  }

  if (isExport && title !== "error") {
    toastTitle = "Message";
  }

  return (
    <Toast bg={toastBg} show={!!showToast} onClose={handleClose}>
      <Toast.Header>
        <i
          className={
            title === "error"
              ? "ri-information-line me-2"
              : "ri-checkbox-circle-fill me-2"
          }
        />
        <strong className="me-auto">{toastTitle}</strong>
      </Toast.Header>
      <Toast.Body className={"danger text-white"}>
        {isExport ? (
          <div>
            {title !== "error" && <CircleFill />} {message}
          </div>
        ) : (
          message
        )}
      </Toast.Body>
    </Toast>
  );
};

const Notification = ({
  message,
  title,
  handleClose,
  type,
}: {
  title?: "error" | "success";
  message: string;
  handleClose?: () => void;
  type?: "export";
}) => {
  return (
    <div className="fixed-top">
      <ToastContainer position="top-end" className="p-3">
        {type === "export" ? (
          <NotificationContent
            title={title}
            message={message}
            isExport={true}
            handleClose={handleClose}
          />
        ) : (
          <NotificationContent
            title={title}
            message={message}
            isExport={false}
            handleClose={handleClose}
          />
        )}
      </ToastContainer>
    </div>
  );
};

export default Notification;
