import { BASE_PATHS } from "src/constant/path";

import AdminRoute from "src/modules/Admin/routes";
import AuthRoute from "src/modules/Auth/routes";

import AppLayout from "src/Layout/AdminLayout";

type Props = {
  path: string;
  Component: React.FunctionComponent<Record<string, never>>;
  Layout: React.FunctionComponent<any>;
  useAuth: boolean;
};

export const BASE_ROUTES: Props[] = [
  {
    path: `${BASE_PATHS.AUTH}/*`,
    Component: AuthRoute,
    Layout: null,
    useAuth: false,
  },
  {
    path: `/*`,
    Component: AdminRoute,
    Layout: AppLayout,
    useAuth: true,
  },
];
