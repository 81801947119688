type BASE_PATHS_PROPS = {
  AUTH: string;
  APP: string;
};

type AUTH_PATHS_PROPS = {
  SIGNIN: string;
  SIGNUP: string;
  FORGOT_PASSWORD: string;
  RESET_PASSWORD: string;
  CREATE_PASSWORD: string;
  VERIFY_EMAIL: string;
  CONFIRM_EMAIL: string;
};

type ADMIN_PATHS_PROPS = {
  HOME: string;
  OVERVIEW: string;
  MERCHANT_USERS: string;
  MERCHANTS: string;
  API_CALLS: string;
  BULK_VERIFICATIONS: string;
  IDENTITY_PASS_PRICING: string;
  RADAR_CHECKS: string;
  IDENTITY_RADAR_PRICING: string;
  BACKGROUND_CHECK: string;
  ROLE_MANAGEMENT: string;
  AUDIT_LOGS: string;
  REFERRAL_MANAGEMENT: string;
  PREMBLY_CERTIFICATES: string;
  ANNOUNCEMENTS: string;
  POLICY_TEMPLATE: string;
  IDENTITY_RADAR: string;
  IDENTITY_PASS: string
};

export const BASE_PATHS: BASE_PATHS_PROPS = {
  AUTH: "auth",
  APP: "app",
};

export const AUTH_PATHS: AUTH_PATHS_PROPS = {
  SIGNIN: "signin",
  SIGNUP: "signup",
  FORGOT_PASSWORD: "forgot-password",
  RESET_PASSWORD: "reset-password",
  CREATE_PASSWORD: "create-password",
  VERIFY_EMAIL: "verify",
  CONFIRM_EMAIL: "confirm",
};

export const ADMIN_PATHS: ADMIN_PATHS_PROPS = {
  HOME: "/",
  OVERVIEW: "overview",
  MERCHANT_USERS: "merchant-users",
  MERCHANTS: "merchants",
  API_CALLS: "api-calls",
  BULK_VERIFICATIONS: "bulk-verifications",
  IDENTITY_PASS_PRICING: "identitypass-pricing",
  RADAR_CHECKS: "radar-checks",
  IDENTITY_RADAR_PRICING: "identity-radar-pricing",
  BACKGROUND_CHECK: "background-check",
  ROLE_MANAGEMENT: "role-management",
  AUDIT_LOGS: "audit-logs",
  REFERRAL_MANAGEMENT: "referral-management",
  PREMBLY_CERTIFICATES: "prembly-certificates",
  ANNOUNCEMENTS: "announcements",
  POLICY_TEMPLATE: "policy-template",
  IDENTITY_RADAR: "identity-radar",
  IDENTITY_PASS: "identity-pass"
};
