import styled from "styled-components";

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  height: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  img {
    width: 200px;
    height: 200px;
    position: absolute;
    top: 4em;
  }
`;
