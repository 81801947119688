import styled from "styled-components";

export const DropdownFilterWrapper = styled.div`
  width: 120%;
  padding: 18px 22px;
  gap: 12px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  position: absolute;
  top: 45px;
  right: 0;
  text-align: left;
  overflow-y: scroll;
  z-index: 10;
  & .export {
    cursor: pointer;
    padding: 0.5em 0px;
    &:hover {
      background: #c0c9d8;
      color: #333333;
    }
  }
`;
