import styled from "styled-components";

import { pxToRem } from "src/helpers/formatFont";

export const StyledContactWrapper = styled.div`
  background: #e9f0fc;
  height: 280px;
  padding: 2em 1em;
  width: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  table {
    thead {
      tr {
        border-color: inherit;
        border-style: none !important;
        border-width: 4;
        th {
          padding: 1em 0px;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          width: 100px !important;
        }
      }
    }
    td {
      padding: 1em 0px;
      font-style: normal;
      font-weight: 400;
      font-size: ${pxToRem(12)};
      width: 100px !important;
    }
  }
`;

export const StyledPreferenceWrapper = styled.div`
  background: #e9f0fc;
  padding: 1em 1em;
  width: 100%;
  box-sizing: border-box;
  & .form-check-label {
    font-size: ${pxToRem(14)};
  }
  & .form-check-input:checked {
    background-color: #003e51;
    border-color: #003e51;
  }
  & .form-check-input:focus {
    box-shadow: none !important;
  }
`;

export const StyledPreferenceHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
  p {
    margin: 0px !important;
  }
`;

export const StyledAddUserWrapper = styled.div`
  Form {
    p {
      font-size: ${pxToRem(12)};
      font-style: normal;
      font-weight: 500;
      margin-bottom: 0px;
    }
  }
`;

export const StyledAddUserHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    border-bottom: 1px solid #e95470;
    margin: 0px;
    padding-bottom: 0.5em;
    font-size: ${pxToRem(14)};
    font-style: normal;
    font-weight: 500;
    color: #1d2939;
  }
`;
