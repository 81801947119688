import { Button as BSButton, Spinner } from "react-bootstrap";

type Props = {
  variant:
    | "primary"
    | "secondary"
    | "success"
    | "warning"
    | "danger"
    | "info"
    | "light"
    | "dark"
    | "link"
    | "outline-primary"
    | "outline-secondary"
    | "outline-success"
    | "outline-warning"
    | "outline-danger"
    | "outline-info"
    | "outline-light"
    | "outline-dark"
    | "outline-link";
  size: "lg" | "sm";
  active?: boolean;
  type: "button" | "reset" | "submit";
  onClick: (e?: any) => void;
  label: string;
  btnIconClassName?: string;
  className?: string;
  isSubmitting?: boolean;
  disabled?: boolean;
};

const Button = ({
  variant,
  size,
  active,
  type,
  label,
  className,
  btnIconClassName,
  onClick,
  isSubmitting,
  disabled,
}: Props) => {
  return (
    <BSButton
      variant={variant}
      size={size}
      disabled={isSubmitting || disabled}
      active={active}
      type={type}
      onClick={onClick}
      className={className}
    >
      {isSubmitting ? (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <>
          {btnIconClassName && (
            <i className={btnIconClassName} style={{ marginRight: ".3em" }} />
          )}
          {label}
        </>
      )}
    </BSButton>
  );
};

export default Button;
