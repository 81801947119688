import {
  ChangeEvent,
  createContext,
  FunctionComponent,
  ReactElement,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { format } from "date-fns";

import { useDebounce } from "src/hooks/useDebounce";

import { IMerchantUser } from "src/interface/Merchants";

import {
  useMerchantsQuery,
  useExportDownloadMerchantsMutation,
} from "src/modules/Admin/services/merchantSlice";

export const MerchantsContext = createContext<{
  response: IMerchantUser[];
  handlePageClick: (e: { selected: number }) => void;
  pageCount: number;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  viewPage: string;
  searchTerm: string;
  handleSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  handleOnChange: (e: any) => void;
  isLoading: boolean;
  isFetching: boolean;
  error: any;
  createdDate: string;
  KybOptions: { label: string; value: string }[];
  kybStatus: any;
  PlateformOptions: { label: string; value: string }[];
  plateform: any;
  startDate: string;
  endDate: string;
  handleFilter: (e: any) => void;
  handleDate: (e: Date, type: string) => void;
  handleExport: (type: "excel" | "pdf") => Promise<void>;
  isOpenExport: boolean;
  setIsOpenExport: any;
  exportError: any;
  pageNumber: number;
  filter: any;
  fileDownload: string;
  setTitle: any;
  title: "success" | "error";
  handleClearFilter: () => void;
  handleSelectOption: (selectedOption: {
    label: string;
    value: SetStateAction<string>;
  }) => void;
  selectedKybStatus: string;
  handleSelectPlateform: (selectedOption: {
    label: string;
    value: SetStateAction<string>;
  }) => void;
  selectedPlateform: string;
  selectedStartDate: string;
  selectedEndDate: string;
}>(null);

type Props = {
  children: ReactElement;
};

const MerchantsProvider: FunctionComponent<Props> = ({ children }) => {
  const [viewPage, setViewPage] = useState<any>(10);
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [response, setResponse] = useState<IMerchantUser[]>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [createdDate, setCreatedDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [_selectedStartDate, _setSelectedStartDate] = useState(null);
  const [_selectedEndDate, _setSelectedEndDate] = useState(null);
  const [_selectedStatus, _setSelectedStatus] = useState(null);
  const [selectedKybStatus, setSelectedKybStatus] = useState("");
  const [kybStatus, setKybStatus] = useState<any>();
  const [selectedPlateform, setSelectedPlateform] = useState("");
  const [plateform, setPlateform] = useState<any>();
  const [isOpenExport, setIsOpenExport] = useState(false);
  const [fileDownload, setFileDownload] = useState("");
  const [title, setTitle] = useState<"success" | "error">();

  const [filter, setFilter] = useState({
    businessName: "",
    businessEmail: "",
  });

  const [selectedBusinessName, setSelectedBusinessName] = useState("");
  const [selectedBusinessEmail, setSelectedBusinessEmail] = useState("");

  const debouncedValue = useDebounce<string>(searchTerm, 500);

  const { data, isLoading, isFetching, error } = useMerchantsQuery({
    view: viewPage,
    page: searchTerm ? 1 : pageNumber + 1,
    search: debouncedValue,
    start_date: selectedStartDate,
    end_date: selectedEndDate,
    business_name: selectedBusinessName,
    business_email: selectedBusinessEmail,
    status: selectedKybStatus,
  });
  const [exportDownload, { error: exportError }] =
    useExportDownloadMerchantsMutation();

  useEffect(() => {
    if (data) {
      setResponse(data?.results);
      setPageCount(Math.ceil(data?.count / viewPage));
    }
  }, [data]);

  const PlateformOptions = [
    { value: "PREMBLY", label: "Prembly" },
    { value: "PELEZA", label: "Peleza" },
  ];

  const KybOptions = [
    { value: "PENDING", label: "Pending" },
    { value: "APPROVED", label: "Approved" },
    { value: "AWAITING", label: "Awaiting" },
  ];

  const handlePageClick = (event: { selected: number }) => {
    setPageNumber(event.selected);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setViewPage(e.target.value);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleClearFilter = () => {
    setFilter({
      businessName: "",
      businessEmail: "",
    });
    setCreatedDate(null);
    setSelectedKybStatus("");
    setSelectedBusinessName("");
    setSelectedBusinessEmail("");
    setPlateform({ label: "", value: "" });
    setKybStatus({ label: "", value: "" });
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setStartDate(null);
    setEndDate(null);
  };

  const handleDate = (date: Date, type: string) => {
    if (type === "start") {
      setStartDate(date);
    } else if (type === "end") {
      setEndDate(date);
    }
  };

  const handleOnChange = (e: any) => {
    const { value, name } = e.target;
    setFilter({ ...filter, [name]: value });
  };

  const handleFilter = () => {
    const start_date = startDate
      ? format(new Date(startDate), "yyyy-MM-dd")
      : null;
    const end_date = endDate ? format(new Date(endDate), "yyyy-MM-dd") : null;

    setSelectedBusinessName(filter.businessName);
    setSelectedBusinessEmail(filter.businessEmail);
    setSelectedPlateform(plateform?.value);
    setSelectedKybStatus(kybStatus?.value);
    setSelectedStartDate(start_date);
    setSelectedEndDate(end_date);
  };

  const handleSelectOption = (selectedOption: {
    label: string;
    value: SetStateAction<string>;
  }) => {
    setKybStatus(selectedOption);
  };

  const handleSelectPlateform = (selectedOption: {
    label: string;
    value: SetStateAction<string>;
  }) => {
    setPlateform(selectedOption);
  };

  const handleExport = async (type: "pdf" | "excel"): Promise<void> => {
    setIsOpenExport(!isOpenExport);
    setFileDownload("File downloading...");
    setTitle("success");
    const payload = {
      start_date: selectedStartDate,
      end_date: selectedEndDate,
      business_name: selectedBusinessName,
      business_email: selectedBusinessEmail,
      status: selectedKybStatus,
      plateform:selectedPlateform,
      export_type: type,
    };
    try {
      const {
        data: {
          test: { url },
        },
      } = await exportDownload({ data: payload }).unwrap();
      if (url) {
        setFileDownload("File downloaded");
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.download = "merchant-users.pdf";
        link.click();
      }
    } catch (error) {
      setFileDownload("An error occurred while exporting data");
      setTitle("error");
    }
  };

  const contextValue = useMemo(
    () => ({
      handlePageClick,
      response,
      pageCount,
      handleChange,
      viewPage,
      searchTerm,
      handleSearch,
      handleOnChange,
      isLoading,
      isFetching,
      error,
      createdDate,
      handleFilter,
      handleDate,
      handleClearFilter,
      handleSelectOption,
      handleSelectPlateform,
      handleExport,
      exportError,
      fileDownload,
      setTitle,
      title,
      isOpenExport,
      setIsOpenExport,
      filter,
      KybOptions,
      kybStatus,
      PlateformOptions,
      plateform,
      pageNumber,
      startDate,
      endDate,
      selectedKybStatus,
      selectedPlateform,
      selectedStartDate,
      selectedEndDate,
    }),
    [response, handlePageClick, viewPage]
  );

  return (
    <MerchantsContext.Provider value={contextValue}>
      {children}
    </MerchantsContext.Provider>
  );
};

export default MerchantsProvider;
