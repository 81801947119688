import styled from "styled-components";

// import { pxToRem } from "src/helpers/formatFont";

export const DateFilter = styled.div`
  // display: flex;
  // align-items: center;
  // border: 1px solid #ced4da;
  // border-radius: 0.375rem;
  // padding: 0.375rem 0.75rem;
  // & .date-input {
  //   width: 100%;
  //   height: 100%;
  // }
  // & .react-datepicker-wrapper {
  //   width: 100%;
  // }
  // & input {
  //   width: calc(100% + 25px);
  //   border: none !important;
  //   background: transparent;
  //   z-index: 1;
  // }
  // & input:focus {
  //   outline: none;
  // }
  display: flex;
  align-items: center;
  gap: 10px;
  & input {
    width: 100%;
    padding: 0.2em 1em;
  }
`;

export const DatePickerWrapper = styled.div`
  width: 45%;
`;
