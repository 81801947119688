import styled from "styled-components";

import { pxToRem } from "src/helpers/formatFont";

export const MerchantUserWrapper = styled.div`
  table {
    thead {
      tr {
        border-color: inherit;
        border-style: none !important;
        border-width: 4;
        th {
          padding: 1em 0px;
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
        }
      }
    }
    td {
      padding: 1em 0px;
      font-style: normal;
      font-weight: 400;
      font-size: ${pxToRem(12)};
    }
  }
  .pagination {
    justify-content: end !important;
  }
`;

export const TopSummaryCard = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  width: 70%;
`;

export const MerchantUserHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e95470;
  padding: 0.8em 0px;
  margin-top: 1em;
  margin-bottom: 1em;
  p {
    margin-bottom: 0px;
  }
`;

export const MerchantUserSearch = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 50%;
`;

export const DropdownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.8em;
  p {
    border-bottom: 1px solid #e95470;
    margin: 0px;
    padding-bottom: 0.5em;
    font-size: ${pxToRem(14)};
    font-style: normal;
    font-weight: 500;
    color: #1d2939;
  }
`;

export const MerchantUserFilter = styled.div`
  position: relative;
  width: 50%;
  text-align: end;
  p {
    font-size: ${pxToRem(12)};
    font-style: normal;
    font-weight: 500;
  }
`;

export const MerchantUserExport = styled.div`
  position: relative;
  width: auto;
  text-align: end;
  p {
    font-size: ${pxToRem(12)};
    font-style: normal;
    font-weight: 500;
    padding: 0px;
    margin-top: 10px;
  }
`;

export const MerchantUserAction = styled.div`
  display: flex;
  gap: 10px;
  width: 50%;
  justify-content: flex-end;
`;

export const DateFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  & input {
    width: 100%;
    padding: 0.2em 1em;
  }
`;
