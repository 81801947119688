// this function removes hyphen from words and replaced with whitespace
export const stripHyphen = (str: string): string => {
  const formattedStr = str.split("-")?.join(" ");
  return formattedStr;
};

// this function adds hyphen to space seperated words
export const unstripHyphen = (str: string): string => {
  const formattedStr = str.split(" ")?.join("-");
  return formattedStr;
};
