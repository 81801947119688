import {
    ChangeEvent,
    createContext,
    FunctionComponent,
    ReactElement,
    SetStateAction,
    // useEffect,
    useMemo,
    useState,
} from "react";
// import { format } from "date-fns";
import { useParams } from "react-router-dom";

// import { useDebounce } from "src/hooks/useDebounce";

// import { useExportDownloadMerchantsMutation } from "src/modules/Admin/services/merchantSlice";
import { useBgcCheckListQuery, usePricingCheckSubservicesQuery, } from "src/modules/Admin/services/backgroundCheckSlice";

export const BackgroundCheckPricingConfigContext = createContext<{
    // response: any;
    handlePageClick: (e: { selected: number }) => void;
    // pageCount: number;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    viewPage: string;
    // searchTerm: string;
    // handleSearch: (e: ChangeEvent<HTMLInputElement>) => void;
    handleOnCheckChange: (e: any) => void;
    checkId: string;
    handleOnCountryChange: (e: any) => void;
    country: { label: string; value: string };
    // isLoading: boolean;
    // isFetching: boolean;
    // error: any;
    // createdDate: string;
    // KybOptions: { label: string; value: string }[];
    // kybStatus: any;
    // startDate: string;
    // endDate: string;
    // handleFilter: (e: any) => void;
    // handleDate: (e: Date, type: string) => void;
    // handleExport: (type: "excel" | "pdf") => Promise<void>;
    isOpenExport: boolean;
    setIsOpenExport: any;
    // exportError: any;
    pageNumber: number;
    // filter: any;
    // fileDownload: string;
    setTitle: any;
    title: "success" | "error";
    // handleClearFilter: () => void;
    // handleSelectOption: (selectedOption: {
    //     label: string;
    //     value: SetStateAction<string>;
    // }) => void;

    checksData: any;
    checksDataIsLoading: boolean;
    checksDataIsFetching: boolean;
    checksDataError: any;

    orgSubservicePricingData: any,
    orgSubservicePricingDataIsLoading: boolean,
    orgSubservicePricingDataIsFetching: boolean,
    orgSubservicePricingDataError: any;

}>(null);

type Props = {
    children: ReactElement;
};

const BackgroundCheckPricingConfigProvider: FunctionComponent<Props> = ({
    children,
}) => {
    const { merchantId } = useParams();

    const [viewPage, setViewPage] = useState<any>(10);
    // const [pageCount, setPageCount] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(0);
    // const [response, setResponse] = useState<[]>();
    const [checkId, setCheckId] = useState<any>({});
    const [country, setCountry] = useState<any>({label:"Nigeria", value:"NG"});
    // const [createdDate, setCreatedDate] = useState(null);
    // const [startDate, setStartDate] = useState(null);
    // const [endDate, setEndDate] = useState(null);
    // const [selectedStartDate, setSelectedStartDate] = useState(null);
    // const [selectedEndDate, setSelectedEndDate] = useState(null);
    // const [selectedKybStatus, setSelectedKybStatus] = useState("");
    // const [kybStatus, setKybStatus] = useState<any>();
    const [isOpenExport, setIsOpenExport] = useState(false);
    // const [fileDownload, setFileDownload] = useState("");
    const [title, setTitle] = useState<"success" | "error">();

    // const [filter, setFilter] = useState({
    //     businessName: "",
    //     businessEmail: "",
    // });

    // const [selectedBusinessName, setSelectedBusinessName] = useState("");
    // const [selectedBusinessEmail, setSelectedBusinessEmail] = useState("");

    // const debouncedValue = useDebounce<string>(checkId?.value, 500);

    // const { data: allSubmittedData, isLoading: allSubmittedDataIsLoading, isFetching: allSubmittedDataIsFetching, error: allSubmittedDataError } = useAllSubmittedDataQuery({
    //     id: requestId,
    // });

    // const { data: allUserData, isLoading: allUserDataIsLoading, isFetching: allUserDataIsFetching, error: allUserDataError } = useGetUsersQuery();

    // usePricingConfigMutation

    const { data: checksData, isLoading: checksDataIsLoading, isFetching: checksDataIsFetching, error: checksDataError } = useBgcCheckListQuery();

    const { data: orgSubservicePricingData, isLoading: orgSubservicePricingDataIsLoading, isFetching: orgSubservicePricingDataIsFetching, error: orgSubservicePricingDataError } = usePricingCheckSubservicesQuery({
        check_id: checkId?.value,
        country: country?.value,
        organisation_id: merchantId,
    });

    // useEffect(() => {
    //     if (checksData) {
    //         console.log(checksData)
    //         // setCheckOptions(data?.detail);
    //     }
    // }, [checksData]);

    // const KybOptions = [
    //   { value: "PENDING", label: "Pending" },
    //   { value: "APPROVED", label: "Approved" },
    //   { value: "AWAITING", label: "Awaiting" },
    //   { value: "ACCEPTED", label: "Accepted" },
    // ];

    const handlePageClick = (event: { selected: number }) => {
        setPageNumber(event.selected);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setViewPage(e.target.value);
    };

    // const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    //     setSearchTerm(e.target.value);
    // };


    // const handleOnChange = (e: any) => {
    //     const { value, name } = e.target;
    // setFilter({ ...filter, [name]: value });
    // };
    const handleOnCheckChange = (selectedOption: {
        label: string;
        value: SetStateAction<string>;
    }) => {
        setCheckId(selectedOption);
    };
    const handleOnCountryChange = (selectedOption: {
        label: string;
        value: SetStateAction<string>;
    }) => {
        setCountry(selectedOption);
    };


    const contextValue = useMemo(
        () => ({
            handlePageClick,
            // response,
            // pageCount,
            handleChange,
            viewPage,
            handleOnCountryChange,
            checkId,
            handleOnCheckChange,
            country,
            // isLoading,
            // isFetching,
            // error,
            // createdDate,
            // exportError,
            // fileDownload,
            setTitle,
            title,
            isOpenExport,
            setIsOpenExport,
            // filter,
            // KybOptions,
            // kybStatus,
            pageNumber,
            // startDate,
            // endDate,
            // selectedKybStatus,
            // selectedStartDate,
            // selectedEndDate,

            checksData,
            checksDataIsLoading,
            checksDataIsFetching,
            checksDataError,

            orgSubservicePricingData,
            orgSubservicePricingDataIsLoading,
            orgSubservicePricingDataIsFetching,
            orgSubservicePricingDataError,
        }),
        [checksData, handlePageClick, viewPage]
    );

    return (
        <BackgroundCheckPricingConfigContext.Provider value={contextValue}>
            {children}
        </BackgroundCheckPricingConfigContext.Provider>
    );
};

export default BackgroundCheckPricingConfigProvider;
