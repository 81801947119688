import { MerchantUserAction, MerchantUserFilter, MerchantUserHeader, MerchantUserSearch } from "../MerchantUsers/styled.merchantuser";
import { Button, CardSkeleton,Notification } from "src/components";
import { AvailableRolesContainer,  RolesEditMainContainer, RolescheckMark } from "./styled.role";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {  useForm,FormProvider, useFormContext } from "react-hook-form";
// import dummyData from './dummyData'
import { useAllRolesAndPermissionsQuery, useSingleRolesAndPermissionsUpdateMutation } from "../../services/RolesAndPermissionSlice";
import {  useState } from "react";
import CreateRole, { RoleAndPermissionsKeysList } from "./CreateRole";


export const RoleSchema = yup.object({
  name:yup.string(),
  // description:yup.string(),
  merchant:yup.object({
    "routes": yup.string(),
    "get": yup.boolean(),
    "post": yup.boolean(),
    "put": yup.boolean(),
    "delete": yup.boolean()
}),
idpass:yup.object({
    "routes": yup.string(),
    "get": yup.boolean(),
    "post": yup.boolean(),
    "put": yup.boolean(),
    "delete": yup.boolean()
}),
radar:yup.object({
    "routes": yup.string(),
    "get": yup.boolean(),
    "post": yup.boolean(),
    "put": yup.boolean(),
    "delete": yup.boolean()
}),
idforms:yup.object({
    "routes": yup.string(),
    "get": yup.boolean(),
    "post": yup.boolean(),
    "put": yup.boolean(),
    "delete": yup.boolean()
}),
bgc:yup.object({
    "routes": yup.string(),
    "get": yup.boolean(),
    "post": yup.boolean(),
    "put": yup.boolean(),
    "delete": yup.boolean()
}),
role_management:yup.object({
    "routes": yup.string(),
    "get": yup.boolean(),
    "post": yup.boolean(),
    "put": yup.boolean(),
    "delete": yup.boolean()
}),
refferal:yup.object({
    "routes": yup.string(),
    "get": yup.boolean(),
    "post": yup.boolean(),
    "put": yup.boolean(),
    "delete": yup.boolean()
}),
certificates:yup.object({
    "routes": yup.string(),
    "get": yup.boolean(),
    "post": yup.boolean(),
    "put": yup.boolean(),
    "delete": yup.boolean()
}),
audit_logs:yup.object({
    "routes": yup.string(),
    "get": yup.boolean(),
    "post": yup.boolean(),
    "put": yup.boolean(),
    "delete": yup.boolean()
}),
id:yup.string(),
})
export type RoleSchemaType = yup.InferType<typeof RoleSchema>

const Role = ()=>{

  const [isOpenEdit,setIsOpenEdit] = useState(false)
  const {isLoading,data,} = useAllRolesAndPermissionsQuery({})
  const [updateRole, { 
    // error: updateRoleError,
    isLoading:updatingRole }]  = useSingleRolesAndPermissionsUpdateMutation();

  const 
  // { 
  //   register,setValue, 
  //   handleSubmit,control,getValues,
  //   formState: { errors },watch,trigger,setError
  // }
  form  = useForm<RoleSchemaType>({ resolver: yupResolver(RoleSchema),defaultValues:{
  }});

  const name = form.watch('name')
  const formDetails = form.watch()
  const [title,setTitle] = useState<any>();
  const [message,setMessage] = useState<any>();
  const handleCloseToast = ()=>{
    setMessage(null)
    setTitle(null)
  }

  const handleSelect=(item:any)=>{
    form.setValue('name',item.name)
//     const defaults = {
//       "get": true,
//       "post": true,
//       "put": true,
//       "delete": true
// }
    RoleAndPermissionsKeysList.map((key)=>{
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
       form.setValue(key, item[key]);
      })
    // form.setValue('idforms',item.idforms)
    // form.setValue('idpass',item.idpass)
    // form.setValue('merchant',item.merchant)
    // form.setValue('radar',item.radar)
    // form.setValue('refferal',item.refferal)
    // form.setValue('role_management',item.role_management)
    form.setValue('id',item.id)


  }
  const handleSubmit = async ()=>{
  // console.log({AllData:formDetails})
    const dataToSubmit = {
      ...form.getValues(),
      description:'dummy decription'
    }
    try{
      const resp = await updateRole({'data':dataToSubmit,'slug':dataToSubmit.id}).unwrap();
      if(resp){
        setTitle("success");
        setMessage('Role Updated')
      }
    }catch(err:any){
      console.log({err})
    }
    return
  }

    return (
        <div>
      <CreateRole
      isOpenEdit={isOpenEdit}
      setIsOpenEdit={setIsOpenEdit}

      // onSubmit={(value:any)=>{
      //   console.log({'onCreate':value})
      // }}
      />

                        <MerchantUserHeader>
            <MerchantUserSearch>
          <p>Role</p>
          
        </MerchantUserSearch>
        <MerchantUserAction >



          <MerchantUserFilter style={{display:'flex','alignItems':'center','gap':'10px',justifyContent:'space-between'}}>
          {/* <Input
            placeholder="Search for Role"
            type="search"
            name="reference"
            onChange={()=>{
            }}
            value={''}
            inputAdornment
          /> */}
          <div/>
            <Button
              label="Create Role"
              size="lg"
              className="text-nowrap"
              type="button"
              variant="outline-primary"
              
            //   btnIconClassName="ri-filter-3-line"
            //   onClick={_handleToggleFilterDropdown}
            onClick={()=>{
                setIsOpenEdit(true)
            }}

            />
            
          </MerchantUserFilter>
        
        </MerchantUserAction>
            </MerchantUserHeader>
            {
              isLoading?
            <CardSkeleton width={'1000px'} height={'400px'}/>
            :

            <FormProvider {...form} >
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'start'}}>
              <AvailableRolesContainer>
                <h2>USER ROLES</h2>

                <div style={{'paddingTop':'25px'}}>
                   {
          data?.data?.filter((filterItem:any)=>!(filterItem.name.toLowerCase().includes('admin')&&filterItem.name.toLowerCase().includes('super'))).map((item:any,index:number)=>(
                      <div className="eachPermissionInstance"
                      key={index}
                      onClick={()=>{
                        handleSelect(item)
                      }}
                      style={{cursor:'pointer'}}
                      >
                      <div style={{'display':'flex','alignItems':'center','gap':'22px'}}>
                        <p className="permission_name">{item.name}</p>
                        {/* <p className="permission_count">9 Permission</p> */}
                      </div>
                      <i
                  className="ri-arrow-right-s-line"
                  style={{
                    fontWeight:
                      "bolder"
                        //  "unset",
                  }}
                />
                    </div>
                    ))
                   }

                </div>
              </AvailableRolesContainer>
                   
                  {
                    name?
                    <RolesEditMainContainer>
                    <h2>{name} Permissions</h2>
                    <br />
                      <div style={{display:'flex',flexDirection:'column',gap:'24px'}}>
                      {
                        [...Object.keys(formDetails)].map((itemKey,index)=>{
                          // console.log({'formdetails':formDetails})
                          // if(RoleAndPermissionsKeysList.includes(itemKey)){
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                            if(formDetails[itemKey]?.routes){
                            return <EachPermissionEdit 
                            label={itemKey.replaceAll('_',' ')}
                            name={itemKey}
                            key={index}
                            />
                          }
                          return'';
                        })
                      }
                      </div>

                      {/* <RoleBtn
                      onClick={handleSubmit}
                      
                      >
                        {
                          updatingRole?
                          'Updating Changes':
                          'Save Changes'
                        }
                        
                      </RoleBtn> */}

                    <Button
                    variant="primary"
                    size="lg"
                    type="button"
                    className="w-full"
                    label='Save Changes'
                    onClick={handleSubmit}
                    isSubmitting={updatingRole}
                    />
                        
                  </RolesEditMainContainer>
:''    
                  }




            </div>
            </FormProvider>           
            }


{/* {
  title?
<Notification
        message={message}
        title={title}
        handleClose={handleCloseToast}
      />:''
} */}
<Notification
        message={message}
        title={title}
        handleClose={handleCloseToast}
      />




        </div>
    )
}

export default Role;


type EachPermissionEditProp ={
label:string,
name:string,
}
export const EachPermissionEdit =({label,name}:EachPermissionEditProp)=>{
  const {setValue,watch} = useFormContext();
  const watchData = watch(name);
  const [open,setOpen] = useState(false)

  // console.log({watchData,name})
  return (
    <div style={{borderBottom:'1px solid #E6E6E6',paddingBottom:'1rem'}}>
      <div 
      onClick={()=>{
        setOpen(!open);
      }}
      style={{display:'flex','alignItems':'center',justifyContent:'space-between',
      cursor:'pointer'
      }}>
      <h2 style={{'fontSize':'.9rem','textTransform':'uppercase',}}>{label}</h2>
      <i className="ri-arrow-drop-down-line"
       style={{
        fontWeight:
          "bolder",
          fontSize:'1.3rem'
            //  "unset",
      }}
      ></i>
      </div>
      {
        open?
        <div style={{display:'flex',gap:'8px',marginTop:'24px'}}>
        {/* {name} */}
        <EachPermissionEditButn
        label="Create/Update"
        onChange={value=>{

          setValue(name,{...watchData,'post':value.isChecked,'put':value.isChecked})
        }}
        defaultChecked={watchData.post}
        id={`${name}-Create/Update`}
        />
        <EachPermissionEditButn
        label="View"
        onChange={value=>{
          // console.log({'View':value})
          setValue(name,{...watchData,'get':value.isChecked,})

        }}
        defaultChecked={watchData.get}

        id={`${name}-View`}
        />
        {/* <EachPermissionEditButn
        label="Delete"
        onChange={value=>{
          setValue(name,{...watchData,'delete':value.isChecked,})

        }}
        defaultChecked={watchData.delete}
        id={`${name}-Delete`}
        /> */}
      </div>:''
      }

    </div>
  )
}




type EachPermissionEditButnProp ={
  label:string,
  onChange:(value:any)=>void,
  id:string,
  defaultChecked?:boolean
}
export const EachPermissionEditButn =({label,onChange,id,defaultChecked=false}:EachPermissionEditButnProp)=>{
  return (
    <div style={{
      backgroundColor:'#F4F4F4',
      display:'inline-block',borderRadius:'32px'
    }}>

      <div 
      style={{
        display:'flex','alignItems':'center',padding:'10px 12px'
      }}
      >
      <div style={{'position':'relative'}}>
      <RolesCheckMarkComp
      onChange={data=>{
        // console.log({'changeValue':data})
        onChange(data)
      }}
      defaultChecked={defaultChecked}
      id={id}
      />
      </div>
      <p style={{'margin':'unset','fontSize':'18px',}}>
      {label}
      </p>
      </div>

    </div>
  )
}






















type RadioBtnWithLabel ={
  type?:'radio'|'checkbox',
  defaultChecked?:boolean,
  // name:string,
  style?:React.CSSProperties,
  value?:string,
  onChange:(data:{value:string,isChecked?:boolean})=>void,
  id:any
}
const RolesCheckMarkComp = ({defaultChecked=false,type='checkbox',onChange,value,id}:RadioBtnWithLabel)=>{


  return (
    <RolescheckMark>
    <label 
// htmlFor={`${value.id}`}
htmlFor={id}

className="radio_container" style={{
'paddingLeft':'25px',
display:'flex',
alignItems:'center',
margin:'unset',

}}>
<input
type={type}
// type="checkbox"
defaultChecked={defaultChecked}
id={id}
checked={defaultChecked}
className="hidden"
onClick={(e:any)=>{
if(onChange){
    onChange({
        'isChecked': e.target?.checked,
        value:value
    })
}
}}
/>

<span className="radio_checkmark__sm" ></span>
{/* <span className="checkmark"></span> */}

</label>
    </RolescheckMark>
  )
}