import styled from "styled-components";

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: flex-end;
`;

export const Icon = styled.i`
  position: absolute;
  margin: 0px 1em;
  cursor: pointer;
  top: 6px;
  right: 0;
`;
