import {
  ReactNode,
  FunctionComponent,
  useState,
  useEffect,
  SVGProps,
} from "react";
import { useLocation } from "react-router-dom";
import { Sidenav, Topnav } from "src/components";

import { ReactComponent as LaptopIcon } from "src/assets/images/Laptop_topnav.svg";
import { ReactComponent as FileSearchIcon } from "src/assets/images/File_search_topnav.svg";
import { ReactComponent as FileXIcon } from "src/assets/images/File_x_topnav.svg";
import { ReactComponent as UserIcon } from "src/assets/images/Users_topnav.svg";
import { ReactComponent as NotificationIcon } from "src/assets/images/notification_top.svg";

import {
  AdminLayoutWrapper,
  MainWrapper,
  MainContainer,
} from "src/Layout/AdminLayout/styled.adminLayout";

type Props = {
  children: ReactNode;
};

const getModuleIcon = (moduleName: string) => {
  switch (moduleName) {
    case "overview":
      return LaptopIcon;
    case "audit logs":
      return FileSearchIcon;
    case "certificate":
    case "announcement":
      return FileXIcon;
    case "notification":
      return NotificationIcon;
    default:
      return UserIcon;
  }
};

const AdminLayout: FunctionComponent<Props> = ({ children }) => {
  const location = useLocation();
  const [pathname, setPathname] = useState([]);
  const [moduleName, setModuleName] = useState("overview");
  const [nestedRoute, setNestedRoute] = useState<any>();

  useEffect(() => {
    const path = location?.pathname?.split("/");
    const nestedRoute = path.length > 4 ? path?.slice(1, 4) : path?.slice(1, 3);
    setPathname(location.pathname.split("/"));
    setModuleName(location.pathname.split("/")[1].replace("-", " "));
    setNestedRoute(nestedRoute);
  }, [location]);

  const ModuleIcon: FunctionComponent<SVGProps<SVGSVGElement>> =
    getModuleIcon(moduleName);

  return (
    <AdminLayoutWrapper>
      <Sidenav pathname={pathname} />
      <MainWrapper>
        <Topnav
          moduleName={moduleName}
          nestedRoute={nestedRoute}
          Icon={ModuleIcon}
        />
        <MainContainer>{children}</MainContainer>
      </MainWrapper>
    </AdminLayoutWrapper>
  );
};

export default AdminLayout;
