import { FunctionComponent, SVGProps } from "react";
import { Link } from "react-router-dom";

import {
  TopnavWrapper,
  TopnavHeader,
} from "src/components/TopNav/styled.topnav";
import { stripHyphen, unstripHyphen } from "src/helpers/formatString";

type Props = {
  moduleName: string;
  nestedRoute?: string[];
  Icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string }>;
};

const TopNav: FunctionComponent<Props> = ({
  moduleName,
  nestedRoute,
  Icon,
}) => {
  return (
    <TopnavWrapper>
      <TopnavHeader>
        <Icon />
        {nestedRoute?.length ? (
          <>
            {/* <Link to={`/${unstripHyphen(moduleName)}`}>{`${moduleName}`}</Link> */}
            {nestedRoute.map((route: string, index: any) => (
              <Link
                to={
                  index === 0
                    ? `${
                        unstripHyphen(moduleName) === "background-check"
                          ? "background-check/requests"
                          : unstripHyphen(moduleName) === "identity-radar"
                          ? `identity-radar/${nestedRoute[1]}`
                          : unstripHyphen(moduleName)
                      }`
                    : nestedRoute[nestedRoute.length - 2]
                    ? `${
                        unstripHyphen(moduleName) === "background-check"
                          ? "background-check/requests"
                          : unstripHyphen(moduleName) === "identity-radar"
                          ? `identity-radar/${nestedRoute[1]}`
                          : unstripHyphen(moduleName)
                      }`
                    : `${unstripHyphen(moduleName)}/${unstripHyphen(route)}`
                }
                style={{
                  pointerEvents:
                    nestedRoute[nestedRoute.length - 1] === route
                      ? "none"
                      : "unset",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {index !== 0 && <i className="ri-arrow-right-s-line" />}
                <p>{stripHyphen(route)}</p>
              </Link>
            ))}
          </>
        ) : (
          <>{`${moduleName}`}</>
        )}
      </TopnavHeader>
    </TopnavWrapper>
  );
};

export default TopNav;
