import { createApi } from "@reduxjs/toolkit/query/react";

import customFetchBase from "src/config/api";
import { parseQueryParams } from "src/helpers/parseQueryParams";
import { IOverview } from "src/interface/ReferralManagement";

export const referralManagementSlice = createApi({
  reducerPath: "referralManagement",
  baseQuery: customFetchBase,
  tagTypes: ["referralHistory"],
  endpoints: (builder) => ({
    referralMgtSummary: builder.query<IOverview, void>({
      query: () => ({
        method: "GET",
        url: "/referrals/overview",
      }),
    }),
    referralHistory: builder.query({
      query: (params) => ({
        method: "GET",
        url: "/referrals",
        params: parseQueryParams(params),
      }),
      providesTags: ["referralHistory"],
    }),
    transactionHistory: builder.query({
      query: (params) => ({
        method: "GET",
        url: "/referrals/transactions",
        params: parseQueryParams(params),
      }),
    }),
    updateCommission: builder.mutation({
      query: (data) => ({
        url: `/referrals/commission`,
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["referralHistory"],
    }),
    referralEarnings: builder.query({
      query: (id) => ({
        url: `/referrals/${id}/earnings`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useReferralMgtSummaryQuery,
  useReferralHistoryQuery,
  useUpdateCommissionMutation,
  useReferralEarningsQuery,
  useTransactionHistoryQuery,
} = referralManagementSlice;
