import { Button, Modal ,Input, Notification, } from "src/components"
import { EditUserModalHeader } from "../../components/ReferralManagement/Referee/styled.referee"
import { useEffect, useState } from "react"
import { yupResolver } from '@hookform/resolvers/yup';
import {  useForm,FormProvider,  } from "react-hook-form";
import { EachPermissionEdit, RoleSchema, RoleSchemaType } from "./Role";
import { useCreateRoleMutation } from "../../services/RolesAndPermissionSlice";


export const RoleAndPermissionsKeysList = [
  'bgc','audit_logs','certificates','idforms','idpass','merchant','radar','refferal','role_management'
]
const CreateRole =({isOpenEdit,setIsOpenEdit,}:any)=>{
    const [title,setTitle] = useState<any>();
    const [message,setMessage] = useState<any>();
    // const [addingRole,setAddingRole] = useState(false);
    const  form  = useForm<RoleSchemaType>({ resolver: yupResolver(RoleSchema),defaultValues:{
  }});
  const name = form.watch('name')
  


  const handleCloseModalEdit =()=>{
    setIsOpenEdit(false)
}
  useEffect(()=>{
    //set all important stuff here
    const defaults = {
            "get": true,
            "post": true,
            "put": true,
            "delete": true
    }
    RoleAndPermissionsKeysList.map((key)=>{
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    return form.setValue(key, { ...defaults, 'routes': key });
    })
    // form.setValue('bgc',{...defaults,'routes':'bgc'});
    // form.setValue('idforms',{...defaults,'routes':'forms'});
    // form.setValue('idpass',{...defaults,'routes':'idpass'})
    // form.setValue('merchant',{...defaults,'routes':'merchants'})
    // form.setValue('radar',{...defaults,'routes':'radar'})
    // form.setValue('refferal',{...defaults,'routes':'refferal'})
    // form.setValue('audit_logs',{...defaults,'routes':'audit_logs'})
    // form.setValue('role_management',{...defaults,'routes':'role_management'})
  },[])

  const [createRole,{
    isLoading:addingRole
  }] =useCreateRoleMutation()

  const handleCreate =async ()=>{
    

    try{
        const resp  = await createRole({data:form.getValues()}).unwrap()
        if(resp){
            setTitle("success");
            setMessage('Role Created')
            setIsOpenEdit(false)
          }
    }catch(err:any){
        console.log({err})
      }
  }
  const handleCloseToast = ()=>{
    setMessage(null)
    setTitle(null)
  }

    return (
        <div>
      <Modal isOpen={isOpenEdit} handleClose={handleCloseModalEdit}>
        <EditUserModalHeader className="mb-2">
          <p>Edit User</p>
          <i
            className="ri-close-circle-line"
            style={{ cursor: "pointer" }}
            onClick={handleCloseModalEdit}
          />
        </EditUserModalHeader>
        
        <FormProvider
        {...form}
        >
        <Input
          label="Role Name"
          value={name}
          onChange={(e)=>{
            form.setValue('name',e.target.value)
          }}
        //   onBlur={handleBlur}
          name="username"
          type="text"
        //   value={`${selectedRow?.first_name} ${selectedRow?.last_name} `}
          placeholder="Enter name of role"
        //   isError={!!errors.percentage}
        //   error={errors.percentage}
        />  

        {
            RoleAndPermissionsKeysList.map((itemKey:string,index)=>{
             return              <EachPermissionEdit
                label={itemKey.replaceAll('_',' ')}
                name={itemKey}
                key={index}
                />
            })
        }

          <br />
        <Button
          variant="primary"
          size="lg"
          type="button"
          className="w-full"
          label='Save Changes'
          onClick={()=>{
            handleCreate()
          }}
          isSubmitting={addingRole}
        />
        </FormProvider>

      </Modal>

      <Notification
        message={message}
        title={title}
        handleClose={handleCloseToast}
      />
        </div>
    )
}
export default CreateRole;