import {
  ChangeEvent,
  createContext,
  FunctionComponent,
  ReactElement,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import useLocalStorage from "src/hooks/useLocalstorage";

import { useDebounce } from "src/hooks/useDebounce";

import { IMerchantUser } from "src/interface/Merchants";

import {
  useTransactionsQuery,
  useExportDownloadMutation,
} from "src/modules/Admin/services/merchantSlice";

export const MerchantTransactionContext = createContext<{
  response: any;
  handlePageClick: (e: { selected: number }) => void;
  pageCount: number;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  viewPage: string;
  searchTerm: string;
  handleSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  isFetching: boolean;
  startDate: string;
  endDate: string;
  selectedStartDate: string;
  selectedEndDate: string;
  amount: string;
  channel: any;
  filter: any;
  transactionOptions: { label: string; value: string }[];
  currencyOptions: { label: string; value: string }[];
  channelOptions: { label: string; value: string }[];
  statusOptions: { label: string; value: string }[];
  handleFilter: (e: any) => Promise<void>;
  handleClearFilter: () => void;
  handleSelectOption: any;
  handleDate: (e: Date, type: string) => void;
  handleStatus: (selectedOption: { value: SetStateAction<string> }) => void;
  handleTransaction: (selectedOption: {
    value: SetStateAction<string>;
  }) => void;
  handleChannel: (selectedOption: { value: SetStateAction<string> }) => void;
  handleCurrency: (selectedOption: { value: SetStateAction<string> }) => void;
  handleExport: (type: "excel" | "pdf") => Promise<void>;
  exportError: any;
  title: "success" | "error";
  fileDownload: string;
  isOpenExport: boolean;
  setIsOpenExport: any;
  setTitle: any;
  handleAmount: (e: any) => void;
  selectedChannel: string;
  selectedTransaction: string;
  selectedStatus: string;
  selectedCurrency: string;
}>(null);

type Props = {
  children: ReactElement;
};

const MerchantTransactionProvider: FunctionComponent<Props> = ({
  children,
}) => {
  const [storedUser, _] = useLocalStorage("cwr_u", null);
  const [viewPage, setViewPage] = useState<any>(10);
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [response, setResponse] = useState<IMerchantUser[]>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [fileDownload, setFileDownload] = useState("");
  const [isOpenExport, setIsOpenExport] = useState<boolean>(false);
  const [title, setTitle] = useState<"success" | "error">();
  const [filter, setFilter] = useState<any>({
    currency: { value: "", label: "" },
    transactionType: { value: "", label: "" },
    transactionChannel: { value: "", label: "" },
    transactionStatus: { value: "", label: "" },
  });

  const [status, setStatus] = useState<string>("");
  const [channel, setChannel] = useState<any>({ label: "", value: "" });
  const [_transaction, setTransaction] = useState<string>("");
  const [_currency, setCurrency] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const debouncedValue = useDebounce<string>(searchTerm, 500);
  const { merchantId } = useParams();

  const { data, isLoading, isFetching } = useTransactionsQuery({
    id: merchantId,
    params: {
      status: selectedStatus,
      type: selectedTransaction,
      currency_code: selectedCurrency,
      channel: selectedChannel,
      amount_from: selectedAmount,
      amount_to: selectedAmount,
      view: viewPage,
      page:
        searchTerm || selectedStartDate || selectedEndDate || status
          ? 1
          : pageNumber + 1,
      query: debouncedValue,
      start_date: selectedStartDate,
      end_date: selectedEndDate,
      loggedUser: storedUser.user.attribute_values.email
    },
  });
  const [exportDownload, { error: exportError }] = useExportDownloadMutation();

  useEffect(() => {
    if (data) {
      setResponse(data?.results);
      setPageCount(Math.ceil(data?.count / viewPage));
    }
  }, [data]);

  const handlePageClick = (event: { selected: number }) => {
    setPageNumber(event.selected);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setViewPage(e.target.value);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleFilter = async (): Promise<void> => {
    const start_date = startDate
      ? format(new Date(startDate), "yyyy-MM-dd")
      : null;
    const end_date = endDate ? format(new Date(endDate), "yyyy-MM-dd") : null;

    setSelectedStartDate(start_date);
    setSelectedEndDate(end_date);
    setSelectedAmount(amount);
    setSelectedChannel(filter.transactionChannel.value);
    setSelectedTransaction(filter.transactionType.value);
    setSelectedStatus(filter.transactionStatus.value);
    setSelectedCurrency(filter.currency.value);
  };

  const handleClearFilter = () => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setSelectedAmount("");
    setAmount("");
    setStartDate(null);
    setEndDate(null);
    setFilter({
      currency: { value: "", label: "" },
      transactionType: { value: "", label: "" },
      transactionChannel: { value: "", label: "" },
      transactionStatus: { value: "", label: "" },
    });
  };

  const handleDate = (date: Date, type: string) => {
    if (type === "start") {
      setStartDate(date);
    } else if (type === "end") {
      setEndDate(date);
    }
  };

  const handleStatus = (selectedOption: { value: SetStateAction<string> }) => {
    setStatus(selectedOption.value);
  };

  const handleChannel = (selectedOption: { value: SetStateAction<string> }) => {
    setChannel(selectedOption.value);
  };

  const handleTransaction = (selectedOption: {
    value: SetStateAction<string>;
  }) => {
    setTransaction(selectedOption.value);
  };

  const handleCurrency = (selectedOption: {
    value: SetStateAction<string>;
  }) => {
    setCurrency(selectedOption.value);
  };

  const handleAmount = (e: any) => {
    setAmount(e.target.value);
  };

  const handleSelectOption = (
    name: string,
    selectedOption: { label: string; value: string }
  ) => {
    setFilter({ ...filter, [name]: selectedOption });
  };

  const handleExport = async (type: "pdf" | "excel"): Promise<void> => {
    setIsOpenExport(!isOpenExport);
    setFileDownload("File downloading...");
    setTitle("success");
    const payload = {
      export_type: type,
      start_date: selectedStartDate,
      end_date: selectedEndDate,
      status: selectedStatus,
      channel: selectedChannel,
      amount: selectedAmount,
      transaction_type: selectedTransaction,
      currency: selectedCurrency,
    };
    try {
      const {
        data: {
          test: { url },
        },
      } = await exportDownload({ id: merchantId, data: payload }).unwrap();
      if (url) {
        setFileDownload("File downloaded");
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.download = "merchant-users.pdf";
        link.click();
      }
    } catch (error) {
      setFileDownload("An error occurred while exporting data");
      setTitle("error");
    }
  };

  const transactionOptions = [
    {
      label: "Credit",
      value: "credit",
    },
    {
      label: "Debit",
      value: "debit",
    },
  ];

  const currencyOptions = [
    {
      label: "NGN",
      value: "NGN",
    },
    {
      label: "USD",
      value: "USD",
    },
  ];

  const channelOptions = [
    {
      label: "Bank Transfer",
      value: "bank_transfer",
    },
    {
      label: "Wallet Transfer",
      value: "wallet_transfer",
    },
    {
      label: "Prembly",
      value: "prembly",
    },
    {
      label: "Card",
      value: "card",
    },
  ];

  const statusOptions = [
    {
      label: "Successful",
      value: "successful",
    },
    {
      label: "Failed",
      value: "failed",
    },
  ];

  const contextValue = useMemo(
    () => ({
      handlePageClick,
      response,
      pageCount,
      handleChange,
      viewPage,
      searchTerm,
      handleSearch,
      isLoading,
      isFetching,
      startDate,
      endDate,
      transactionOptions,
      currencyOptions,
      channelOptions,
      statusOptions,
      amount,
      channel,
      handleFilter,
      handleClearFilter,
      handleDate,
      handleStatus,
      handleTransaction,
      handleChannel,
      handleAmount,
      handleSelectOption,
      handleCurrency,
      handleExport,
      fileDownload,
      title,
      isOpenExport,
      filter,
      exportError,
      setIsOpenExport,
      setTitle,
      selectedStartDate,
      selectedEndDate,
      selectedChannel,
      selectedTransaction,
      selectedCurrency,
      selectedStatus,
    }),
    [response, handlePageClick, viewPage]
  );

  return (
    <MerchantTransactionContext.Provider value={contextValue}>
      {children}
    </MerchantTransactionContext.Provider>
  );
};

export default MerchantTransactionProvider;
