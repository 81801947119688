import { createApi } from "@reduxjs/toolkit/query/react";

import customFetchBase from "src/config/api";
import { parseQueryParams } from "src/helpers/parseQueryParams";


export const notificationSlice = createApi({
  reducerPath: "notification",
  baseQuery: customFetchBase,
  tagTypes: ['notification'],
  endpoints: (builder) => ({
    notifications: builder.query<any, 
    {
      view: number;
      page: number;
    }
    >({
      query: (params) => ({
        method: "GET",
        url: "/notifications",
        params: parseQueryParams(params),
      }),
      providesTags: ['notification']
    }),
    readNotification: builder.mutation<any, string>({
      query: (id) => ({
        method: "POST",
        url: `/notifications`,
        body: {
          notification_id: id
        }
      }),
      invalidatesTags: ['notification']
    })
  }),
});

export const {
  useNotificationsQuery,
  useReadNotificationMutation
} = notificationSlice;
