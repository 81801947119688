import {
  ChangeEvent,
  createContext,
  FunctionComponent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useNotificationsQuery } from "src/modules/Admin/services/notificationSlice";

export const AdminNotificationContext = createContext<{
  response: any;
  isLoading: boolean;
  isFetching: boolean;
  handlePageClick: (e: { selected: number }) => void;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  viewPage: number;
  pageCount: number;
  error: any;
}>(null);

type Props = {
  children: ReactElement;
};

const AdminNotificationProvider: FunctionComponent<Props> = ({ children }) => {
  const [response, setResponse] = useState();
  const [viewPage, setViewPage] = useState<number>(10);
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);

  const { data, isLoading, isFetching, error } = useNotificationsQuery(
    {
      view: viewPage,
      page: pageNumber + 1,
    },
    { skip: viewPage < 1 }
  );

  useEffect(() => {
    if (data) {
      setResponse(data);
      setPageCount(Math.ceil(data?.count / viewPage));
    }
  }, [data]);

  const handlePageClick = (event: { selected: number }) => {
    setPageNumber(event.selected);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setViewPage(Number(e.target.value));
    setPageNumber(0);
  };

  const contextValue = useMemo(
    () => ({
      response,
      isLoading,
      isFetching,
      handlePageClick,
      handleChange,
      viewPage,
      pageCount,
      error,
    }),
    [response, isLoading, isFetching, viewPage]
  );

  return (
    <AdminNotificationContext.Provider value={contextValue}>
      {children}
    </AdminNotificationContext.Provider>
  );
};

export default AdminNotificationProvider;
