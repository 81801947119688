import { createApi } from "@reduxjs/toolkit/query/react";

import customFetchBase from "src/config/api";
import { parseQueryParams } from "src/helpers/parseQueryParams";
import { IExportDownload, IOverview } from "src/interface/Overview";

export const overviewSlice = createApi({
  reducerPath: "overview",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    overviewUserSummary: builder.query<IOverview, void>({
      query: () => ({
        method: "GET",
        url: "/overview",
      }),
    }),
    overviewWalletTransaction: builder.query({
      query: (params) => ({
        method: "GET",
        url: "https://api.prembly.com/identitypass-production/wallet/superadmin/fund-wallets",
        headers: {
          "email": params[1].loggedUser
        },
        params: parseQueryParams(params[0]),

        }),
    }),
    exportDownload: builder.mutation<
      IExportDownload,
      { export_type: "pdf" | "excel" }>
      ({
      query: (data) => ({
        url: "/merchants/transactions/wallet/export",
        method: "POST",
        body: { ...data },
      }),
    }),
  }),
});

export const {
  useOverviewUserSummaryQuery,
  useOverviewWalletTransactionQuery,
  useExportDownloadMutation,
} = overviewSlice;
