import {
  ChangeEvent,
  createContext,
  FunctionComponent,
  ReactElement,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { format } from "date-fns";

import { useExportDownloadMerchantsMutation } from "src/modules/Admin/services/merchantSlice";
import { useRequestQuery, useSearchByParamMutation } from "src/modules/Admin/services/backgroundCheckSlice";

export const BackgroundCheckRequestContext = createContext<{
  response: [];
  handlePageClick: (e: { selected: number }) => void;
  handleNextPageClick: (e: { selected: number }) => void;
  pageCount: number;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  viewPage: string;
  phoneSearchTerm: string;
  handleSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  searchTerm: string;
  handlePhoneSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  handleOnChange: (e: any) => void;
  isLoading: boolean;
  isFetching: boolean;
  error: any;
  createdDate: string;
  RequestStatusOptions: { label: string; value: string }[];
  PaymentOptions: { label: string; value: string }[];
  kybStatus: any;
  checkStatus: any;
  requestStatus: any;
  paymentStatus: any;
  startDate: string;
  endDate: string;
  handleDefaultFilter: (val: any, data:any) => void;
  handleFilter: (e: any) => void;
  handleDate: (e: Date, type: string) => void;
  handleExport: (type: "excel" | "pdf") => Promise<void>;
  isOpenExport: boolean;
  setIsOpenExport: any;
  exportError: any;
  pageNumber: number;
  filter: any;
  fileDownload: string;
  setTitle: any;
  title: "success" | "error";
  handleClearFilter: () => void;
  handleSelectOption: (
    name: string,
    selectedOption: {
      label: string;
      value: SetStateAction<string>;
    }) => void;
  handleSelectCheckStatusOption: (selectedOption: {
    label: string;
    value: SetStateAction<string>;
  }) => void;
  handleSelectStatusOption: (selectedOption: {
    label: string;
    value: SetStateAction<string>;
  }) => void;
  handleSelectPaymentOption: (selectedOption: {
    label: string;
    value: SetStateAction<string>;
  }) => void;
  handleTempFilter: () => void;
  selectedKybStatus: string;
  selectedStartDate: string;
  selectedEndDate: string;
  lastEvalKey:any
}>(null);

type Props = {
  children: ReactElement;
};

const BackgroundCheckRequestProvider: FunctionComponent<Props> = ({
  children,
}) => {
  const [viewPage, setViewPage] = useState<any>(10);
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageDataId, setPageDataId] = useState("");
  const [response, setResponse] = useState<[]>();
  const [lastEvalKey, setLastEvalKey] = useState({});
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [phoneSearchTerm, setPhoneSearchTerm] = useState<string>("");
  const [phoneData, setPhoneData] = useState<any>(
    { detail: [], status: false, status_code:"" }
  );
  const [createdDate, setCreatedDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedKybStatus, setSelectedKybStatus] = useState("");
  const [kybStatus, setKybStatus] = useState<any>();
  const [checkStatus, setCheckStatus] = useState<any>(
    { label: "Paid and Accepted", value: "PA" }
  );
  const [requestStatus, setRequestStatus] = useState<any>(
    { value: "ACCEPTED", label: "Accepted" }
  );
  const [paymentStatus, setPaymentStatus] = useState<any>(
    { value: "true", label: "Paid" }
  );
  const [isOpenExport, setIsOpenExport] = useState(false);
  const [fileDownload, setFileDownload] = useState("");
  const [title, setTitle] = useState<"success" | "error">();
  // const [paymentStatus, setPaymentStatus] = useState({
  //   { value: "PENDING", label: "Pending" },
  //   businessName: "",
  //   businessEmail: "",
  // });

  const [filter, setFilter] = useState({
    businessName: "",
    businessEmail: "",
  });

  const [selectedBusinessName, setSelectedBusinessName] = useState("");
  const [selectedBusinessEmail, setSelectedBusinessEmail] = useState("");

  const { data, isLoading, isFetching, error, } = useRequestQuery({
    // view: viewPage,
    last_evaluated_key: pageDataId,
    // page: searchTerm ? 1 : pageNumber + 1,
    start_date: selectedStartDate,
    end_date: selectedEndDate,
    business_name: selectedBusinessName,
    business_email: selectedBusinessEmail,
    status: selectedKybStatus,
  });

  const [exportDownload, { error: exportError }] =
    useExportDownloadMerchantsMutation();

  const [searchByParam, { isLoading: searchParamLoading }] = useSearchByParamMutation();

  useEffect(() => {
    if (data) {
      // if(searchTerm){
      //   handleSearch(searchTerm)
      //   handleDefaultFilter(checkStatus?.value, data)
      //   setLastEvalKey(data?.last_evaluated_key)
      //   setPageCount(Math.ceil(data?.count / viewPage));

      // }
      // else{
        // setResponse(data?.detail);
        // handleDefaultFilter("PA")
        handleDefaultFilter(checkStatus?.value, data)
        setLastEvalKey(data?.last_evaluated_key)
        setPageCount(Math.ceil(data?.count / viewPage));
      // }
    }
  }, [data]);
  

  const RequestStatusOptions = [
    { value: "PENDING", label: "Pending" },
    { value: "CONSENT", label: "Consented" },
    { value: "ACCEPTED", label: "Accepted" },
    { value: "SUBMITTED", label: "Submitted" },
    { value: "PROCESSING", label: "Processing" },
    { value: "IN PROGRESS", label: "In Progress" },
    { value: "COMPLETED", label: "Completed" },
  ];

  const PaymentOptions = [
    { value: "true", label: "Paid" },
    { value: "false", label: "Not Paid" },
  ];

  const handlePageClick = (event: { selected: number }) => {
    setPageNumber(event.selected);
  };

  const handleNextPageClick = (event: { selected: any }) => {
    console.log(event)
    setPageDataId(JSON?.stringify(data?.last_evaluated_key))
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setViewPage(e.target.value);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    if (e.target.value.length > 0) {
      const res: any = response?.filter((v: { candidate_name: string, merchant: string, candidate_email: string }) =>
        v.candidate_name?.toLowerCase()?.includes(e.target.value?.toLowerCase()) ||
        v.merchant?.toLowerCase()?.includes(e.target.value?.toLowerCase()) ||
        v.candidate_email?.toLowerCase()?.includes(e.target.value?.toLowerCase())
      );
      // setResponse(res);
      handleDefaultFilter(checkStatus?.value, {detail:res})
    } else {
      // setResponse(data?.detail);
      handleDefaultFilter(checkStatus?.value, data)
    }
  };

  const handlePhoneSearch = async(e: ChangeEvent<HTMLInputElement>) => {
    setPhoneSearchTerm(e.target.value);
    if (!e.target.value){
      // setResponse(data?.detail)
      handleDefaultFilter(checkStatus?.value, data)
      setLastEvalKey(data?.last_evaluated_key)
      setPageCount(Math.ceil(data?.count / viewPage));
    }
    else{
      try {
        const reqResponse = await searchByParam({
          phone_number: e.target.value,
        }).unwrap();
        if (reqResponse) {
          setTitle("success");
          setPhoneData(reqResponse)
          // setResponse(reqResponse?.detail)
          handleDefaultFilter(checkStatus?.value, reqResponse )
          
          // setMessage(reqResponse.detail);
        }
      } catch (error: any) {
        setTitle("error");
        // setMessage(error.data.detail);
      }
    }

  };

  const handleClearFilter = () => {
    setFilter({
      businessName: "",
      businessEmail: "",
    });
    setCreatedDate(null);
    setSelectedKybStatus("");
    setSelectedBusinessName("");
    setSelectedBusinessEmail("");
    setKybStatus({ label: "", value: "" });
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setStartDate(null);
    setEndDate(null);
    setRequestStatus(null);
    setPaymentStatus(null);
    setResponse(data?.detail);
    handleDefaultFilter(checkStatus?.value, data)
  };

  const handleDate = (date: Date, type: string) => {
    if (type === "start") {
      setStartDate(date);
    } else if (type === "end") {
      setEndDate(date);
    }
  };

  const handleOnChange = (e: any) => {
    const { value, name } = e.target;
    setFilter({ ...filter, [name]: value });
  };

  const handleSelectCheckStatusOption = (selectedOption: {
    label: string;
    value: SetStateAction<string>;
  }) => {
    if(phoneSearchTerm){
      handleDefaultFilter(selectedOption?.value, phoneData)
    }
    else{
      handleDefaultFilter(selectedOption?.value, data)
    }
    setCheckStatus(selectedOption);
  };

  const handleDefaultFilter = (val:any, fullData:any) => {
    if (val === "ALL") {
      setResponse(fullData?.detail);
      handleSelectPaymentOption(null)
      handleSelectStatusOption(null)
    }
    if (val === "PA") {
      setResponse(fullData?.detail?.filter((v: any) => (v?.request_status?.toUpperCase() === "ACCEPTED") && (String(v?.payment) === String("true"))));
      handleSelectPaymentOption({ value: "true", label: "Paid" })
      handleSelectStatusOption({ value: "ACCEPTED", label: "Accepted" })
    }
    if (val === "PS") {
      setResponse(fullData?.detail?.filter((v: any) => (v?.request_status?.toUpperCase() === "SUBMITTED") && (String(v?.payment) === String("true"))));
      handleSelectPaymentOption({ value: "true", label: "Paid" })
      handleSelectStatusOption({ value: "SUBMITTED", label: "Submitted" })
    }
    if (val === "PC") {
      setResponse(fullData?.detail?.filter((v: any) => (v?.request_status?.toUpperCase() === "CONSENT") && (String(v?.payment) === String("true"))));
      handleSelectPaymentOption({ value: "true", label: "Paid" })
      handleSelectStatusOption({ value: "CONSENT", label: "Consented" })
    }
    if (val === "PP") {
      setResponse(fullData?.detail?.filter((v: any) => (v?.request_status?.toUpperCase() === "PENDING") && (String(v?.payment) === String("true"))));
      handleSelectPaymentOption({ value: "true", label: "Paid" })
      handleSelectStatusOption({ value: "PENDING", label: "Pending" })
    }
    if (val === "PPR") {
      setResponse(fullData?.detail?.filter((v: any) => (v?.request_status?.toUpperCase() === "PROCESSING") && (String(v?.payment) === String("true"))));
      handleSelectPaymentOption({ value: "true", label: "Paid" })
      handleSelectStatusOption({ value: "PROCESSING", label: "Processing" })
    }
    if (val === "PI") {
      setResponse(fullData?.detail?.filter((v: any) => ((v?.request_status?.toUpperCase() === "IN PROGRESS") || (v?.request_status?.toUpperCase() === "IN-PROGRESS") ) && (String(v?.payment) === String("true"))));
      handleSelectPaymentOption({ value: "true", label: "Paid" })
      handleSelectStatusOption({ value: "IN PROGRESS", label: "In Progress" })
    }
    if (val === "PCO") {
      setResponse(fullData?.detail?.filter((v: any) => (v?.request_status?.toUpperCase() === "COMPLETED") && (String(v?.payment) === String("true"))));
      handleSelectPaymentOption({ value: "true", label: "Paid" })
      handleSelectStatusOption({ value: "COMPLETED", label: "Completed" })
    }
    if (val === "UA") {
      setResponse(fullData?.detail?.filter((v: any) => (v?.request_status?.toUpperCase() === "ACCEPTED") && (String(v?.payment) === String("false"))));
      handleSelectPaymentOption({ value: "false", label: "Not Paid" })
      handleSelectStatusOption({ value: "ACCEPTED", label: "Accepted" })
    }
    if (val === "US") {
      setResponse(fullData?.detail?.filter((v: any) => (v?.request_status?.toUpperCase() === "SUBMITTED") && (String(v?.payment) === String("false"))));
      handleSelectPaymentOption({ value: "false", label: "Not Paid" })
      handleSelectStatusOption({ value: "SUBMITTED", label: "Submitted" })
    }
    if (val === "UC") {
      setResponse(fullData?.detail?.filter((v: any) => (v?.request_status?.toUpperCase() === "CONSENT") && (String(v?.payment) === String("false"))));
      handleSelectPaymentOption({ value: "false", label: "Not Paid" })
      handleSelectStatusOption({ value: "CONSENT", label: "Consented" })
    }
    if (val === "UP") {
      setResponse(fullData?.detail?.filter((v: any) => (v?.request_status?.toUpperCase() === "PENDING") && (String(v?.payment) === String("false"))));
      handleSelectPaymentOption({ value: "false", label: "Not Paid" })
      handleSelectStatusOption({ value: "PENDING", label: "Pending" })
    }
    if (val === "UPR") {
      setResponse(fullData?.detail?.filter((v: any) => (v?.request_status?.toUpperCase() === "PROCESSING") && (String(v?.payment) === String("false"))));
      handleSelectPaymentOption({ value: "false", label: "Not Paid" })
      handleSelectStatusOption({ value: "PROCESSING", label: "Processing" })
    }
    if (val === "UI") {
      setResponse(fullData?.detail?.filter((v: any) => ((v?.request_status?.toUpperCase() === "IN PROGRESS") || (v?.request_status?.toUpperCase() === "IN-PROGRESS")) && (String(v?.payment) === String("false"))));
      handleSelectPaymentOption({ value: "false", label: "Not Paid" })
      handleSelectStatusOption({ value: "IN PROGRESS", label: "In Progress" })
    }
    if (val === "UCO") {
      setResponse(fullData?.detail?.filter((v: any) => (v?.request_status?.toUpperCase() === "COMPLETED") && (String(v?.payment) === String("false"))));
      handleSelectPaymentOption({ value: "false", label: "Not Paid" })
      handleSelectStatusOption({ value: "COMPLETED", label: "Completed" })
    }
    // const res = data?.detail.filter((v: any) => (v?.request_status?.toUpperCase() === requestStatus?.value) && (String(v?.payment) === String(paymentStatus?.value)));
    // setResponse(res);
  };


  const handleFilter = () => {
    const start_date = startDate
      ? format(new Date(startDate), "yyyy-MM-dd")
      : null;
    const end_date = endDate ? format(new Date(endDate), "yyyy-MM-dd") : null;

    setSelectedBusinessName(filter.businessName);
    setSelectedBusinessEmail(filter.businessEmail);
    setSelectedKybStatus(kybStatus?.value);
    setSelectedStartDate(start_date);
    setSelectedEndDate(end_date);
  };

  const handleTempFilter = () => {
    const res = data?.detail.filter((v: any) => {
      if (paymentStatus?.value && requestStatus?.value) {
        return (
          v?.request_status.toUpperCase() === requestStatus?.value &&
          String(v?.payment) === String(paymentStatus?.value)
        );
      } else if (paymentStatus?.value && !requestStatus?.value) {
        return String(v.payment) === String(paymentStatus?.value);
      } else {
        return v?.request_status?.toUpperCase() === requestStatus?.value;
      }
    });

    setResponse(res);
    handleDefaultFilter(checkStatus?.value, res )
  };

  const handleSelectOption = (
    name: string,
    selectedOption: { label: string; value: SetStateAction<string>; }) => {
    setKybStatus(selectedOption);
    setFilter({ ...filter, [name]: selectedOption });
  };

  const handleSelectStatusOption = (selectedOption: {
    label: string;
    value: SetStateAction<string>;
  }) => {
    setRequestStatus(selectedOption);
  };

  const handleSelectPaymentOption = (selectedOption: {
    label: string;
    value: SetStateAction<string>;
  }) => {
    setPaymentStatus(selectedOption);
  };

  const handleExport = async (type: "pdf" | "excel"): Promise<void> => {
    setIsOpenExport(!isOpenExport);
    setFileDownload("File downloading...");
    setTitle("success");
    const payload = {
      start_date: selectedStartDate,
      end_date: selectedEndDate,
      business_name: selectedBusinessName,
      business_email: selectedBusinessEmail,
      status: selectedKybStatus,
      export_type: type,
    };
    try {
      const {
        data: {
          test: { url },
        },
      } = await exportDownload({ data: payload }).unwrap();
      if (url) {
        setFileDownload("File downloaded");
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.download = "merchant-users.pdf";
        link.click();
      }
    } catch (error) {
      setFileDownload("An error occurred while exporting data");
      setTitle("error");
    }
  };

  const contextValue = useMemo(
    () => ({
      handlePageClick,
      handleNextPageClick,
      response,
      pageCount,
      handleChange,
      viewPage,
      phoneSearchTerm,
      handlePhoneSearch,
      searchTerm,
      handleSearch,
      handleOnChange,
      searchParamLoading,
      isLoading,
      isFetching,
      error,
      createdDate,
      handleSelectCheckStatusOption,
      handleDefaultFilter,
      handleFilter,
      handleDate,
      handleClearFilter,
      handleSelectOption,
      handleExport,
      handleSelectStatusOption,
      handleSelectPaymentOption,
      exportError,
      fileDownload,
      setTitle,
      title,
      isOpenExport,
      setIsOpenExport,
      filter,
      RequestStatusOptions,
      PaymentOptions,
      kybStatus,
      pageNumber,
      startDate,
      endDate,
      selectedKybStatus,
      selectedStartDate,
      selectedEndDate,
      checkStatus,
      paymentStatus,
      requestStatus,
      handleTempFilter,
      lastEvalKey,
    }),
    [response, handleNextPageClick, handlePageClick, viewPage]
  );


  return (
    <BackgroundCheckRequestContext.Provider value={contextValue}>
      {children}
    </BackgroundCheckRequestContext.Provider>
  );
};

export default BackgroundCheckRequestProvider;
