import {
  createContext,
  FunctionComponent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";

import { useNotificationQuery } from "src/modules/Admin/services/merchantSlice";

export const NotificationContext = createContext<{
  response: any;
  isLoading: boolean;
  isFetching: boolean;
}>(null);

type Props = {
  children: ReactElement;
};

const NotificationProvider: FunctionComponent<Props> = ({ children }) => {
  const [response, setResponse] = useState();
  const { merchantId } = useParams();

  const { data, isLoading, isFetching } = useNotificationQuery(merchantId);

  useEffect(() => {
    if (data) {
      setResponse(data);
    }
  }, [data]);

  const contextValue = useMemo(
    () => ({
      response,
      isLoading,
      isFetching,
    }),
    [response, isLoading, isFetching]
  );

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
