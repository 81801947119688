import { createSlice } from "@reduxjs/toolkit";

export interface AccountState {
  isLoggedIn: boolean;
  userId: string;
  user: any;
}

const clu = !!localStorage.getItem("clu"); // Check if custom logged user (clu) is set in localstorage

const cwr_u = JSON.parse(localStorage.getItem("cwr_u") || "{}");

const username = {
  firstname: cwr_u?.usr?.attribute_values?.first_name || "",
  lastname: cwr_u?.user?.attribute_values?.last_name || "",
};

const initialState: AccountState = {
  isLoggedIn: clu,
  userId: "",
  user: username,
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    loggedState: (state, action) => {
      state.isLoggedIn = action.payload.login;
    },
    getUserID: (state, action) => {
      state.userId = action.payload;
    },
    getUser: (state, action) => {
      state.user = action.payload.user;
    },
  },
});

export const { loggedState, getUserID, getUser } = accountSlice.actions;

export default accountSlice.reducer;
