import { FunctionComponent, ReactNode } from "react";
import { Modal as BSModal } from "react-bootstrap";

import { ModalWrapper } from "src/components/Modal/styled.modal";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  size?: "sm" | "lg" | "xl" | "default";
  children: ReactNode;
};

const Modal: FunctionComponent<Props> = ({
  isOpen,
  handleClose,
  size,
  children,
}) => {
  return (
    <BSModal
      show={isOpen}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered={true}
      size={size !== "default" ? size : size === "default" ? null : "sm"}
    >
      <ModalWrapper>{children}</ModalWrapper>
    </BSModal>
  );
};

export default Modal;
