import {
  ChangeEvent,
  createContext,
  Dispatch,
  FunctionComponent,
  ReactElement,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";

import { IUser_Data } from "src/interface/Merchants";
import { useSubscriptionQuery } from "src/modules/Admin/services/identityRadarSlice";

// import { useUsersQuery } from "src/modules/Admin/services/merchantSlice";

export const SubscriptionContext = createContext<{
  response: any;
  handlePageClick: (e: { selected: number }) => void;
  pageCount: number;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  viewPage: string;
  isLoading: boolean;
  isFetching: boolean;
  handleCloseModal: () => void;
  handleOpenNewUserModal: () => void;
  isAddNewUser: boolean;
  setIsAddNewUser: Dispatch<SetStateAction<boolean>>;
}>(null);

type Props = {
  children: ReactElement;
};

const SubscriptionProvider: FunctionComponent<Props> = ({ children }) => {
  const [viewPage, setViewPage] = useState<any>(10);
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [response, setResponse] = useState<IUser_Data[]>();
  const [isAddNewUser, setIsAddNewUser] = useState<boolean>(false);
  const { subscriptionId } = useParams();

  const { data, isLoading, isFetching } = useSubscriptionQuery({
    subscriptionId: subscriptionId,
    // params: {
    //   view: viewPage,
    //   page: pageNumber + 1,
    // },
  });

  console.log(pageNumber);

  console.log(subscriptionId, "pcd", data);

  useEffect(() => {
    if (data) {
      setResponse(data?.data);
      setPageCount(Math.ceil(data?.count / viewPage));
    }
  }, [data]);

  const handlePageClick = (event: { selected: number }) => {
    setPageNumber(event.selected);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setViewPage(Number(e.target.value));
  };

  const handleOpenNewUserModal = () => {
    setIsAddNewUser(!isAddNewUser);
  };

  const handleCloseModal = () => {
    setIsAddNewUser(!isAddNewUser);
  };

  const contextValue = useMemo(
    () => ({
      handlePageClick,
      response,
      pageCount,
      handleChange,
      viewPage,
      isLoading,
      isFetching,
      handleOpenNewUserModal,
      isAddNewUser,
      setIsAddNewUser,
      handleCloseModal,
    }),
    [response, handlePageClick, viewPage]
  );

  return (
    <SubscriptionContext.Provider value={contextValue}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionProvider;
