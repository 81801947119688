import styled from "styled-components";

import { pxToRem } from "src/helpers/formatFont";

export const Wrapper = styled.div`
  .password-strength-bar {
    display: flex;
    gap: 8px;
    width: 100%;
    height: 4px;
    border-radius: 5px;
  }

  .password-strength-bar__dash {
    width: 33.3%;
    height: 100%;
    border-radius: 2px;
  }

  .password-strength-bar__dash.filled {
    background-color: #ffc400;
  }
  .password-strength-bar__dash.filled-first {
    background-color: #ef4836;
  }
  .password-strength-bar__dash.filled-second {
    background-color: #ffc400;
  }
  .password-strength-bar__dash.filled-third {
    background-color: #25c281;
  }

  .password-strength-bar__dash.empty {
    background-color: #e0e0e0;
  }
  .weak {
    color: #ef4836;
  }
  .medium {
    color: #ffc400;
  }
  .strong {
    color: #25c281;
  }
`;

export const ScoreWord = styled.p`
  display: flex;
  font-size: ${pxToRem(12)};
  justify-content: end;
`;
