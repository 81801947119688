
import { Form, Table } from "react-bootstrap"
import { 
    // DropdownHeader,
    MerchantUserAction, MerchantUserFilter, MerchantUserHeader, MerchantUserSearch } from "../MerchantUsers/styled.merchantuser"
import { Button, Modal ,Input, CardSkeleton, Notification} from "src/components"
import StatusDiv from "src/components/StatusDiv/StatusDiv"
import { useState } from "react"
import OverviewMenu from "src/components/OverviewMenu";
import { EditUserModalHeader } from "../../components/ReferralManagement/Referee/styled.referee"
import { useAssignUserToRoleMutation, useUsersAndRolesQuery } from "../../services/RolesAndPermissionSlice"
import { format } from "date-fns";
import { useGetAllUserWithIDQuery } from "../../services/backgroundCheckSlice"
import { toTitleCase } from "src/helpers/toTitleCase"
// import * as Yup from "yup";

// const editUserSchema= Yup.object().shape({
//     user_name:Yup.string().required('Required'),
//     role:Yup.string().reque
// });
const RoleManagement = ()=>{
const [showMenu, setShowMenu] = useState<boolean>(false);
const [isOpenEdit,setIsOpenEdit] = useState(false);
const [role,setRole] = useState<any>();
// const [isUserEdit]  
const [selectedRow, setSelectedRow] = useState<any>();
const handleSelectRole =(selectedOption:any)=>{
    setRole(selectedOption)
}
  const _handleShow = (user:any) => {
        setShowMenu(true);
        setSelectedRow(user);
        console.log("Hlellow")
      };
    const handleCloseModalEdit = ()=>{
        setIsOpenEdit(!isOpenEdit);
    }
    const [message, setMessage] = useState(null);
    const [title, setTitle] = useState<"success" | "error">();
    
    const handleCloseToast = async () => {
      setMessage(null);
      setTitle(null);
    };
    const {isLoading,data:allRoles} = useUsersAndRolesQuery();
    const {data:allUserData,
      isLoading:isLoadingUsers
    } = useGetAllUserWithIDQuery()
    const [addRole,{isLoading:addingRole}] = useAssignUserToRoleMutation()
    
    const handleAddRoleTOUser = async ()=>{
      console.log({selectedRow,role})
      if(!role){
        setTitle("error");
        setMessage('Please Pick Role');
        return 
      }

      try{
        const resp:any = await addRole({
          role:role.value.replaceAll(' ','-'),
          userid:selectedRow.id
        }).unwrap();
        if(resp){
          console.log({resp})
          setShowMenu(false)
          setIsOpenEdit(false)
          setTitle("success");
          setMessage(resp?.message	);
          setTimeout(()=>{
            window.location.reload()
          },3000)
        }
      }catch(err:any){
        //
      }
    }
    return (
        <div>
            <MerchantUserHeader>
            <MerchantUserSearch>
          <p>Role Management</p>
          
        </MerchantUserSearch>
        <MerchantUserAction>



          <MerchantUserFilter style={{display:'flex','alignItems':'center','gap':'10px'}}>
          <Input
            placeholder="Search for User"
            type="search"
            name="reference"
            onChange={()=>{
                //
            }}
            value={''}
            inputAdornment
          />
            <Button
              label="Add A User"
              size="lg"
              type="button"
              variant="outline-primary"
              className="text-nowrap"
            //   btnIconClassName="ri-filter-3-line"
            //   onClick={_handleToggleFilterDropdown}
            onClick={()=>{
                //
            }}
            />
            {/* <Dropdown isOpen={isOpenFilter} height="447px">
              <DropdownHeader>
                <p>Filters</p>
                <i
                  className="ri-close-circle-line"
                  style={{ cursor: "pointer" }}
                  onClick={_handleClose}
                />
              </DropdownHeader>
             

              <div className="mb-3">
                <p className="mb-1">BY MODULE TYPE</p>
                <Input
                  type="select"
                  selectPlaceholder={<p>Select Module</p>}
                  selectOptions={MODULETYPEOptions}
                  selectValue={module}
                  handleChangeOption={handleModule}
                />
              </div>
              <div className="mb-3">
                <p className="mb-1">By ACTION</p>
                <Input
                  type="select"
                  selectPlaceholder={<p>Select Action status</p>}
                  selectOptions={ActionOptions}
                  selectValue={actionValue}
                  handleChangeOption={handleSetActionValue}
                />
              </div>
              <div className="mb-2">
                <p className="mb-1">Date created</p>
                
                 <Input
                  type="date"
                  selectPlaceholder={<p>Date created</p>}
                  onChange={e=>{
                    setStartDate(e.target.value)
                  }}
                  value={startDate}
               />
              </div>
              
              <Button
                type="button"
                label="Clear Filter"
                onClick={handleClearFilter}
                variant="link"
                size="lg"
                className="w-full mt-2 text-dark"
              />
            </Dropdown> */}
          </MerchantUserFilter>
        
        </MerchantUserAction>
            </MerchantUserHeader>

            {
                (isLoading||isLoadingUsers)?
        <CardSkeleton height={'300'} />
                :
                <Table>
                <thead>
                  <tr>
                    {/* <th>
                          {merchantIds?.includes(item?.id) ?
                            <i className="ri-checkbox-line ri-xl" onClick={()=> removeMerchant(item?.id)} style={{cursor:"pointer"}}/>
                            :
                            <i className="ri-checkbox-blank-line ri-xl" onClick={()=> addMerchant(item?.id)} style={{cursor:"pointer"}}/>
                          }
                    </th> */}
                    <th>S/N</th>
                    <th>Full Name</th>
                    <th>EMAIL ADDRESS</th>
                    <th>ROLE</th>
                    <th>LAST LOG IN</th>
                    <th>STATUS</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>

                    {
                        allUserData?.data.map((user:any,index:number)=>(
                            <tr key={index}>
                            <td>{index+1}</td>
                            <td>{user?.first_name} {user?.last_name}</td>
                            <td>{user?.email}</td>
                            <td>{
                            toTitleCase(user?.role?.replaceAll('-',' ')??"Not Assigned")
                            }</td>
                            <td>
                    {format(new Date(user?.last_login	), "dd MMM yyyy. hh:mm a")}

                            </td>
                            <td>
                                <StatusDiv colorType='green'>
                                    ACTIVE
                                </StatusDiv>
                            </td>
                            <td>
                                <OverviewMenu
                                selectedRow={selectedRow}
                                data={user}
                                show={showMenu}
                                menus={[
                                // {
                                // name: "Assign Role",
                                // action: () =>{
                                //   setIsOpenEdit(true)
                                // },
                                // },
                                {
                                name: "Edit",
                                action: () =>{
                                    setIsOpenEdit(true)
                                },
                                },
                                {
                                name: "Delete",
                                action: () =>{
                                //
                                },
                                },
                                ]}
                                onClick={() => _handleShow(user)}
                                />
                            </td>
                        </tr>
                            
                        ))
                    }

                </tbody>
              </Table>
            }





      <Modal isOpen={isOpenEdit} handleClose={handleCloseModalEdit}>
        <EditUserModalHeader className="mb-2">
          <p>Edit User</p>
          <i
            className="ri-close-circle-line"
            style={{ cursor: "pointer" }}
            onClick={handleCloseModalEdit}
          />
        </EditUserModalHeader>
       
        <Input
          label="User Name"
        //   value={values.percentage}
        //   onChange={handleChange}
        //   onBlur={handleBlur}
          name="username"
          type="text"
          value={`${selectedRow?.first_name} ${selectedRow?.last_name} `}
          placeholder="Modupe Odunyemi"
        //   isError={!!errors.percentage}
        //   error={errors.percentage}
        />


            <Form.Label>Roles</Form.Label>
            <Input
            // label="Check Status"
            type="select"
            selectOptions={
              allRoles?.data.map((role:any)=>({
                label:role.name.replaceAll('-',' '),
                value:role.id
              }))
            //   [
            //   { label: "Supeadmin", value: "Supeadmin" },
            //   { label: "Business Owner", value: "Business Owner" },
            //   { label: "Product Admin", value: "Product Admin" },
            //   { label: "User", value: "User" },
            // ]
          }
            selectPlaceholder={<p></p>}
            selectValue={role}
            handleChangeOption={handleSelectRole}
          />
          <br />
        <Button
          variant="primary"
          size="lg"
          type="button"
          className="w-full"
          label='Save Changes'
          onClick={()=>{
            //
            handleAddRoleTOUser()
            
          }}
          isSubmitting={addingRole}
        />
      </Modal>


<Notification
            title={title}
            message={message}
            handleClose={handleCloseToast}
        />
        </div>
    )
}

export default RoleManagement;