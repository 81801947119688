import styled from "styled-components";

import { pxToRem } from "src/helpers/formatFont";

export const TopSummaryCard = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
`;

export const TopSummaryViewRequestCard = styled.div`
width: 33.3%;
`

export const TopSummaryViewCandidateCard = styled.div`
width: 33.3%;
`

export const BackgroundCheckHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e95470;
  padding: 0.8em 0px;
  margin-top: 1em;
  margin-bottom: 1em;
  p {
    margin-bottom: 0px;
  }
`;

export const BackgroundCheckSearch = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 50%;
`;

export const BackgroundCheckAction = styled.div`
  display: flex;
  gap: 10px;
  width: 50%;
  justify-content: flex-end;
`;

export const BackgroundCheckFilter = styled.div`
  position: relative;
  width: 50%;
  text-align: end;
  p {
    font-size: ${pxToRem(12)};
    font-style: normal;
    font-weight: 500;
  }
`;

export const DropdownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.8em;
  p {
    border-bottom: 1px solid #e95470;
    margin: 0px;
    padding-bottom: 0.5em;
    font-size: ${pxToRem(14)};
    font-style: normal;
    font-weight: 500;
    color: #1d2939;
  }
`;

export const DateFilter = styled.div`
  // display: flex;
  // align-items: center;
  // border: 1px solid #ced4da;
  // border-radius: 0.375rem;
  // padding: 0.375rem 0.75rem;
  // & .date-input {
  //   width: 100%;
  //   height: 100%;
  // }
  // & .react-datepicker-wrapper {
  //   width: 100%;
  // }
  // & input {
  //   width: calc(100% + 25px);
  //   border: none !important;
  //   background: transparent;
  //   z-index: 1;
  // }
  // & input:focus {
  //   outline: none;
  // }
  display: flex;
  align-items: center;
  gap: 10px;
  & input {
    width: 100%;
    padding: 0.2em 1em;
  }
`;

export const BackgroundCheckExport = styled.div`
  position: relative;
  width: auto;
  text-align: end;
  p {
    font-size: ${pxToRem(12)};
    font-style: normal;
    font-weight: 500;
    padding: 0px;
    margin-top: 10px;
  }
`;

export const DatePickerWrapper = styled.div`
  width: 45%;
`;
