import { createApi } from "@reduxjs/toolkit/query/react";

import customFetchBase from "src/config/api";
import { parseQueryParams } from "src/helpers/parseQueryParams";
import { ISummary } from "src/interface/Merchants";

export const merchantSlice = createApi({
  reducerPath: "merchant",
  baseQuery: customFetchBase,
  tagTypes: [
    "merchantSummary",
    "merchants",
    "users",
    "subscriptions",
    "transactions",
    "businessInformation",
    "notifications",
    "billingProfile",
    "transactionSummary"
  ],
  endpoints: (builder) => ({
    merchantSummary: builder.query<ISummary, void>({
      query: () => ({
        method: "GET",
        url: "/overview/merchants",
      }),
      providesTags: ["merchantSummary"],
    }),
    merchants: builder.query<
      any,
      {
        view: number;
        page: number;
        search?: string;
        plateform?: string;
        status?: string;
        start_date: string;
        end_date: string;
        business_name?: string;
        business_email?: string;
        kyc_status?: string;
      }
    >({
      query: (params) => ({
        method: "GET",
        url: "/merchants",
        params: parseQueryParams(params),
      }),
      providesTags: ["merchants"],
    }),
    updateStatus: builder.mutation({
      query: (data) => ({
        method: "post",
        url: "/merchants/status",
        body: { ...data },
      }),
      invalidatesTags: ["merchantSummary", "merchants"],
    }),
    bulkMerchantBlock: builder.mutation({
      query: (data) => ({
        method: "post",
        url: "/merchants/block-merchants",
        body: { ...data },
      }),
      invalidatesTags: ["merchantSummary", "merchants"],
    }),
    businessInformation: builder.query({
      query: (id) => ({
        method: "GET",
        url: `/merchants/${id}/get`,
      }),
      providesTags: ["businessInformation"],
    }),
    subscriptions: builder.query({
      query: (id) => ({
        method: "GET",
        url: `/merchants/${id}/subscription`,
      }),
      providesTags: ["subscriptions"],
    }),
    addPlan: builder.mutation({
      query: ({ data, id }) => ({
        method: "POST",
        url: `/merchants/${id}/subscription`,
        body: { ...data },
      }),
      invalidatesTags: ["subscriptions"],
    }),
    subPlans: builder.query<any, { id: string; type: string }>({
      query: ({ id, type }) => ({
        method: "GET",
        url: `/subscription/plans?product=${id}&subscription_type=${type}`,
      }),
    }),
    products: builder.query<any, void>({
      query: () => ({
        method: "GET",
        url: `/products`,
      }),
    }),
    transactionSummary: builder.query({
      query: ({merchantId, email}) => ({
        method: "GET",
        headers: {
          "email": email
        },
        url: `https://api.prembly.com/identitypass/wallet/superadmin/statement-of-account/analytics/${merchantId}`,
      }),
      providesTags: ["transactionSummary"],
    }),
    transactions: builder.query({
      query: ({ id, params }) => ({
        method: "GET",
        headers: {
          "email": params.loggedUser
        },
        url: `https://api.prembly.com/identitypass-production/wallet/superadmin/statement-of-account?organisation_id=${id}`,
        params: parseQueryParams(params),
      }),
      providesTags: ["transactions"],
    }),
    debitWallet: builder.mutation({
      query: ({ data, email }) => ({
        method: "POST",
        headers: {
          "email": email
        },
        url: `https://api.prembly.com/identitypass-production/wallet/superadmin/fund-wallets`,
        body: { ...data },
      }),
      invalidatesTags: ["transactions", "transactionSummary"],
    }),
    autoFunding: builder.mutation({
      query: ({ data, id }) => ({
        method: "PUT",
        url: `merchants/${id}/wallet/autofunding`,
        body: { ...data },
      }),
    }),
    thresholdSetting: builder.mutation({
      query: ({ data, id }) => ({
        method: "PUT",
        url: `/merchants/${id}/wallet/threshold`,
        body: { ...data },
      }),
    }),
    users: builder.query({
      query: ({ id, params }) => ({
        method: "GET",
        url: `/merchants/${id}/users`,
        params: parseQueryParams(params),
      }),
      providesTags: ["users"],
    }),
    addUser: builder.mutation({
      query: ({ id, data }) => ({
        method: "POST",
        url: `/merchants/${id}/users/invite`,
        body: { ...data },
      }),
      invalidatesTags: ["users"],
    }),
    notification: builder.query({
      query: (id) => ({
        url: `/merchants/${id}/notification`,
        method: "GET",
      }),
      providesTags: ["notifications"],
    }),
    addContact: builder.mutation({
      query: ({ id, data }) => ({
        url: `/merchants/${id}/contacts`,
        method: "POST",
        body: { ...data },
      }),
      invalidatesTags: ["notifications"],
    }),
    notificationUpdate: builder.mutation({
      query: ({ id, data }) => ({
        url: `/merchants/${id}/notification`,
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["notifications"],
    }),
    certificateUpload: builder.mutation({
      query: ({ id, data }) => ({
        method: "POST",
        url: `/merchants/${id}/document`,
        body: data,
      }),
      invalidatesTags: ["businessInformation"],
    }),
    resetUpload: builder.mutation({
      query: (id) => ({
        method: "DELETE",
        url: `/merchants/${id}/document`,
      }),
      invalidatesTags: ["businessInformation"],
    }),
    certifyDocument: builder.mutation({
      query: ({ id, data }) => ({
        method: "POST",
        url: `/merchants/${id}/document-approval`,
        body: { ...data },
      }),
      invalidatesTags: ["businessInformation", "merchants"],
    }),
    changeBvnAccessStatus: builder.mutation({
      query: ({ id, data }) => ({
        method: "POST",
        url: `/merchants/${id}/update-bvn-approval`,
        body: { ...data },
      }),
      invalidatesTags: ["businessInformation", "merchants"],
    }),
    updateTierStatus: builder.mutation({
      query: ({ id, data }) => ({
        method: "POST",
        url: `/merchants/${id}/update-account-tier`,
        body: { ...data },
      }),
      invalidatesTags: ["businessInformation", "merchants"],
    }),
    exportDownload: builder.mutation<any, any>({
      query: ({ id, data }) => ({
        url: `/merchants/${id}/transactions/export`,
        method: "POST",
        body: { ...data },
      }),
    }),
    billingProfile: builder.query({
      query: ({ params, product, id }) => ({
        url: product == "IDENTITYPASS" ? 
          `${process.env.REACT_APP_PASS_URL}/internal/core/admin/endpoint/pricings/${id}` : 
          product == "IDENTITYRADAR" ? `${process.env.REACT_APP_RADAR_URL}/api/v1/internal/organisation/${id}/subscription`  : '',
        method: "GET",
        params: parseQueryParams(params),
      }),
      providesTags: ["billingProfile"],
    }),
    updateBilling: builder.mutation({
      query: ({ id, data, product }) => ({
        url: product == "IDENTITYPASS" ?  `${process.env.REACT_APP_PASS_URL}/internal/core/admin/endpoints/update-pricings` :
        product == "IDENTITYRADAR" ? `${process.env.REACT_APP_RADAR_URL}'/api/v1/internal/organisation/${id}/subscription/update` : '',
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: ["billingProfile"],
    }),
    exportDownloadMerchants: builder.mutation<any, any>({
      query: ({ data }) => ({
        url: `/merchants/export`,
        method: "POST",
        body: { ...data },
      }),
    }),
  }),
});

export const {
  useMerchantSummaryQuery,
  useMerchantsQuery,
  useUpdateStatusMutation,
  useBulkMerchantBlockMutation,
  useBusinessInformationQuery,
  useSubscriptionsQuery,
  useAddPlanMutation,
  useTransactionSummaryQuery,
  useTransactionsQuery,
  useDebitWalletMutation,
  useAutoFundingMutation,
  useThresholdSettingMutation,
  useUsersQuery,
  useAddUserMutation,
  useSubPlansQuery,
  useNotificationQuery,
  useProductsQuery,
  useCertificateUploadMutation,
  useCertifyDocumentMutation,
  useChangeBvnAccessStatusMutation,
  useUpdateTierStatusMutation,
  useNotificationUpdateMutation,
  useExportDownloadMutation,
  useAddContactMutation,
  useResetUploadMutation,
  useBillingProfileQuery,
  useUpdateBillingMutation,
  useExportDownloadMerchantsMutation,
} = merchantSlice;
