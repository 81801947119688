import { FunctionComponent } from "react";
import { useSelector } from "react-redux";

import Avatar from "src/components/Avatar";

import { UserCardWrapper } from "src/components/UserCard/styled.usercard";

import { RootState } from "src/store";

const UserCard: FunctionComponent<Record<string, never>> = () => {
  const { user } = useSelector((state: RootState) => state.account);

  return (
    <UserCardWrapper>
      <>
        <Avatar />
      </>
      <>{user && <p>{`${user.firstname} ${user.lastname}`}</p>}</>
    </UserCardWrapper>
  );
};

export default UserCard;
