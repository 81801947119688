import { FunctionComponent } from "react";
import { ReactComponent as LogoText } from "src/assets/images/loadingPageLogo.svg";
import LogoIcon from "src/assets/images/loadingPageGif.gif";

import { StyledWrapper } from "src/components/Loader/styled.appLoader";

const Loader: FunctionComponent<Record<never, string>> = () => {
  return (
    <StyledWrapper>
      <LogoText />
      <img src={LogoIcon} alt="" />
    </StyledWrapper>
  );
};

export default Loader;
