import {
  ChangeEvent,
  createContext,
  FunctionComponent,
  ReactElement,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useDebounce } from "src/hooks/useDebounce";

import { useTransactionHistoryQuery } from "src/modules/Admin/services/referralManagementSlice";

export const TransactionHistoryContext = createContext<{
  response: any;
  isLoading: boolean;
  isFetching: boolean;
  pageCount: number;
  pageNumber: number;
  viewPage: number;
  searchTerm: string;
  handlePageClick: (e: { selected: number }) => void;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  handleStatus: (selectedOption: { value: SetStateAction<string> }) => void;
}>(null);

type Props = {
  children: ReactElement;
};

const TransactionHistoryProvider: FunctionComponent<Props> = ({ children }) => {
  const [viewPage, setViewPage] = useState<number>(10);
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [response, setResponse] = useState();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const debouncedValue = useDebounce<string>(searchTerm, 500);

  const { data, isLoading, isFetching } = useTransactionHistoryQuery({
    view: viewPage,
    page: searchTerm || status ? 1 : pageNumber + 1,
    search: debouncedValue,
  });

  useEffect(() => {
    if (data) {
      setResponse(data?.results);
      setPageCount(Math.ceil(data?.count / viewPage));
    }
  }, [data]);

  const handlePageClick = (event: { selected: number }) => {
    setPageNumber(event.selected);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setViewPage(Number(e.target.value));
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleStatus = (selectedOption: { value: SetStateAction<string> }) => {
    setStatus(selectedOption.value);
  };

  const contextValue = useMemo(
    () => ({
      response,
      isLoading,
      isFetching,
      pageCount,
      pageNumber,
      viewPage,
      searchTerm,
      handlePageClick,
      handleChange,
      handleSearch,
      handleStatus,
    }),
    [response, viewPage, handlePageClick]
  );

  return (
    <TransactionHistoryContext.Provider value={contextValue}>
      {children}
    </TransactionHistoryContext.Provider>
  );
};

export default TransactionHistoryProvider;
