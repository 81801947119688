import { FunctionComponent } from "react";

import {
  Wrapper,
  ScoreWord,
} from "src/components/PasswordStrengthBar/style.passwordStrengthBar";

type Props = {
  password: string;
};

const PasswordStrengthBar: FunctionComponent<Props> = ({ password }) => {
  const passwordRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\-_=+{};:,<.>])(?=.*[a-zA-Z]).{7,}$/;

  let filledDashes = 0;
  if (password.length >= 1 && password.length <= 7) {
    filledDashes = 1;
  } else if (!passwordRegex.test(password) && password.length > 1) {
    filledDashes = 2;
  } else if (passwordRegex.test(password) && password.length > 1) {
    filledDashes = 3;
  }

  const renderDashedBar = () => {
    const dashCount = 3;
    const dashes = [];

    for (let i = 0; i < dashCount; i++) {
      let dashClass = "empty";
      if (i === 0 && filledDashes === 1) {
        dashClass = "filled-first";
      } else if ((i === 0 || i === 1) && filledDashes === 2) {
        dashClass = "filled-second";
      } else if ((i === 0 || i === 1 || i === 2) && filledDashes === 3) {
        dashClass = "filled-third";
      }
      dashes.push(
        <div className={`password-strength-bar__dash ${dashClass}`} key={i} />
      );
    }
    return dashes;
  };

  let scoreWord = "";
  if (password.length >= 1 && password.length <= 7) {
    scoreWord = "Weak";
  } else if (!passwordRegex.test(password) && password.length > 1) {
    scoreWord = "Medium";
  } else if (passwordRegex.test(password) && password.length > 1) {
    scoreWord = "Strong";
  }

  let className = "mt-2 ";
  if (filledDashes === 1) {
    className += "weak";
  } else if (filledDashes === 2) {
    className += "medium";
  } else {
    className += "strong";
  }

  return (
    <Wrapper>
      <div className="password-strength-bar">{renderDashedBar()}</div>
      <ScoreWord className={className}>{scoreWord}</ScoreWord>
    </Wrapper>
  );
};

export default PasswordStrengthBar;
