import { FunctionComponent } from "react";
import ContentLoader from "react-content-loader";

type Props = {
  height: number | string;
  width?: number | string;
};

const CardSkeleton: FunctionComponent<Props> = ({ height, width }) => {
  return (
    <ContentLoader
      viewBox={`0 0 ${width || "380"} ${height || "130"}`}
      backgroundColor="rgba(0,0,0,0.06)"
      foregroundColor="rgba(0,0,0,0.12)"
    >
      <rect x="0" y="0" rx="5" ry="5" width={"100%"} height={"100vh"} />
    </ContentLoader>
  );
};

export default CardSkeleton;
