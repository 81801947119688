import { FunctionComponent, ChangeEvent, FocusEvent, ReactNode } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";

import { FormWrapper, Icon } from "src/components/Input/styled.input";
import { pxToRem } from "src/helpers/formatFont";

type Props = {
  label?: string;
  placeholder?: string;
  type: "email" | "password" | "text" | "search" | "textarea" | "select" |'date';
  name?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  value?: string | number;
  error?: string;
  isError?: boolean;
  inputAdornment?: boolean;
  handleTogglePassword?: () => void;
  inputAdornmentClassName?: string;
  disabled?: boolean;
  selectOptions?: any;
  selectPlaceholder?: ReactNode;
  handleChangeOption?: (option: any) => void;
  selectValue?: any;
  isOptionLoading?: boolean;
};

const Input: FunctionComponent<Props> = ({
  placeholder,
  type,
  label,
  name,
  onBlur,
  onChange,
  value,
  error,
  isError,
  inputAdornment,
  inputAdornmentClassName,
  handleTogglePassword,
  disabled,
  selectOptions,
  selectPlaceholder,
  handleChangeOption,
  selectValue,
  isOptionLoading,
}) => {
  if (type === "search") {
    return (
      <Form.Group controlId={label}>
        <FormWrapper>
          <Form.Control
            type={type}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            isInvalid={isError}
          />
          {inputAdornment && (
            <Icon className="ri-search-line" onClick={handleTogglePassword} />
          )}
        </FormWrapper>
      </Form.Group>
    );
  }

  if (type === "textarea") {
    return (
      <Form.Group controlId={label}>
        <Form.Label>{label}</Form.Label>
        <FormWrapper>
          <Form.Control
            as="textarea"
            rows={4}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            name={name}
            isInvalid={isError}
          />
          {error && (
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          )}
        </FormWrapper>
      </Form.Group>
    );
  }

  if (type === "select") {
    return (
      <Form.Group controlId={label}>
        {label &&
          <Form.Label>{label}</Form.Label>
        }
        <Select
        options={selectOptions}
        placeholder={selectPlaceholder}
        value={!selectValue?.value ? null : selectValue}
        onChange={handleChangeOption}
        isLoading={isOptionLoading}
        // onChange={(selected: any) => handleSelectOption(selected)}
        styles={{
          option: (styles) => ({
            ...styles,
            fontSize: pxToRem(12),
          }),
          singleValue: (provided) => ({
            ...provided,
            fontSize: pxToRem(12),
          }),
        }}
      />

      </Form.Group>
    );
  }
  // if (type === "select") {
  //   return (
  //     <Select
  //       options={selectOptions}
  //       placeholder={selectPlaceholder}
  //       value={!selectValue?.value ? null : selectValue}
  //       onChange={handleChangeOption}
  //       isLoading={isOptionLoading}
  //       name={name}
  //       // onChange={(selected: any) => handleSelectOption(selected)}
  //       styles={{
  //         option: (styles) => ({
  //           ...styles,
  //           fontSize: pxToRem(12),
  //         }),
  //         singleValue: (provided) => ({
  //           ...provided,
  //           fontSize: pxToRem(12),
  //         }),
  //       }}
  //     />
  //   );
  // }
  return (
    <Form.Group className="mb-3" controlId={label}>
      <Form.Label>{label}</Form.Label>
      <FormWrapper>
        <Form.Control
          type={type}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          isInvalid={isError}
          disabled={disabled}
        />
        {inputAdornment && (
          <Icon
            className={inputAdornmentClassName}
            onClick={handleTogglePassword}
          />
        )}
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </FormWrapper>
    </Form.Group>
  );
};

export default Input;
