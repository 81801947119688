import styled from "styled-components";

import { pxToRem } from "src/helpers/formatFont";

export const UserCardWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: rgba(17, 75, 101, 0.42);
  border-radius: 4px;
  padding: ${pxToRem(10)} ${pxToRem(12)};
  width: 85%;
  margin: 0px auto;
  :nth-child(1) {
    font-size: ${pxToRem(14)};
    font-weight: 700 !important;
    margin: 0px;
  }
  :nth-child(2) {
    color: #ecedef;
    font-size: ${pxToRem(12)};
    font-weight: 300;
    margin: 0px;
  }
`;
