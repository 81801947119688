import styled from "styled-components";

export const AdminLayoutWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const MainWrapper = styled.main`
  width: 83%;
  margin-left: 17%;
`;

export const MainContainer = styled.div`
  padding: 20px 40px;
`;
