import { FunctionComponent } from "react";

import { ReactComponent as MoreIcon } from "src/assets/images/more.svg";

import { MenuWrapper, MenuContainer } from "src/components/Menu/styled.menu";

type MenuProp = {
  action: () => void;
  name: string;
};

type DataProps = {
  id: string | number;
};

type SelectedRowProps = {
  id: string | number;
};

type Props = {
  show: boolean;
  selectedRow: SelectedRowProps;
  data: DataProps;
  onClick: () => void;
  menus: MenuProp[];
};

const Menu: FunctionComponent<Props> = ({
  show,
  selectedRow,
  data,
  onClick,
  menus,
}) => {
  return (
    <MenuWrapper>
      <MoreIcon
        style={{
          textAlign: "center",
          width: "30%",
          cursor: "pointer",
        }}
        onClick={onClick}
      />
      {show && selectedRow?.id === data?.id ? (
        <MenuContainer>
          {menus?.map((menu) => (
            <p key={menu.name} onClick={menu.action}>
              {menu.name}
            </p>
          ))}
        </MenuContainer>
      ) : null}
    </MenuWrapper>
  );
};

export default Menu;
