import styled from "styled-components";

import { pxToRem } from "src/helpers/formatFont";

export const SidenavWrapper = styled.aside`
  position: fixed;
  text-decoration: none;
  padding: 20px 0px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #003e51;
  height: 100vh;
  width: 17%;
  flex: 1;
  overflow-y: scroll;
`;

export const SidenavItem = styled.div`
  width: 100%;
`;

export const SidenavContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  align-items: center;
  .active {
    background: #114b65;
  }
  a {
    display: flex;
    align-items: center;
    margin: 0.6em auto;
    text-decoration: none;
    color: #ffffff;
    font-size: ${pxToRem(13)};
    border-radius: 4px;
    font-style: normal;
    padding: ${pxToRem(10)} ${pxToRem(10)};
    width: 85%;
  }
`;

export const SidenavMenu = styled.p`
  flex: 1;
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 0px;
`;

export const SidenavChildWrapper = styled.div`
  width: 80%;
  margin-left: 25%;
  a {
    font-size: ${pxToRem(12)};
    margin: 0px;
    padding: 10px 0px;
  }
`;

export const SidenavChildMenu = styled.p``;

export const StyledLogout = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 3em 0px 0px 1.5em;
  padding: 0.625rem 0.625rem;
  font-size: ${pxToRem(12)};
`;

export const NotificationCount = styled.span`
  font-size: ${pxToRem(9)};
  background-color: #fff;
  color: #000;
  border-radius: 50%;
  width: ${pxToRem(17)};
  height: ${pxToRem(17)};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`