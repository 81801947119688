import DatePicker from "react-datepicker";

import {
  DateFilter,
  DatePickerWrapper,
} from "src/components/DateRange/styled.daterange";

type Props = {
  startDate: any;
  endDate: any;
  handleStartDate: any;
  handleEndDate: any;
};
const DateRange = ({
  startDate,
  endDate,
  handleStartDate,
  handleEndDate,
}: Props) => {
  return (
    <DateFilter>
      <DatePickerWrapper>
        <DatePicker
          selectsStart
          startDate={startDate}
          endDate={endDate}
          selected={startDate}
          onChange={(date: any) => handleStartDate(date, "start")}
          placeholderText="Start Date"
        />
      </DatePickerWrapper>
      -
      <DatePickerWrapper>
        <DatePicker
          selected={endDate}
          onChange={(date: any) => handleEndDate(date, "end")}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          placeholderText="End Date"
        />
      </DatePickerWrapper>
    </DateFilter>
  );
};

export default DateRange;
