import { pxToRem } from "src/helpers/formatFont";
import styled from "styled-components";

import { CardWrapperProps } from "src/interface/Card";

export const CardWrapper = styled.div<CardWrapperProps>`
  background: #005e7a;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 30px 20px;
  color: #ffffff;
  width: ${(props) => props.width || "100%"};
`;

export const CardContainer = styled.div`
  flex: 1;
`;

export const CardWrapperCount = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: ${pxToRem(34)};
  margin: 0;
`;

export const CardWrapperLabel = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: ${pxToRem(12)};
  margin: 0;
`;
