import styled from "styled-components";

export const MenuWrapper = styled.div`
  position: relative;
`;

export const MenuContainer = styled.div`
  background: #ffffff;
  padding: 5px 12px;
  border: 2px solid #d9dde3;
  border-radius: 4px;
  width: fit-content;
  position: absolute;
  // text-align: center;
    top: 25px;
    left: -75px;
  z-index: 10;
  p {
    margin: 0px;
    padding: 4px 12px;
    cursor: pointer;
    &:hover {
      background: #c0c9d8;
      color: #333333;
    }
  }
`;
