import styled from "styled-components";

export const MenuWrapper = styled.div`
  position: relative;
  text-align: center;
`;

export const MenuContainer = styled.div`
  background: #ffffff;
  padding: 5px 12px;
  border: 1px solid #d9dde3;
  border-radius: 4px;
  width: fit-content;
  position: absolute;
  left: -95px;
  top: 25px;
  z-index: 10;
  p {
    margin: 0px;
    padding: 4px 12px;
    cursor: pointer;
    text-align: left;
    &:hover {
      background: #c0c9d8;
      color: #333333;
    }
  }
`;
