import styled from "styled-components";



export const AvailableRolesContainer = styled.div`
padding: 1rem;
border: 1px solid #E6E6E6;
border-radius: 8px;
font-family:  '--general-sans-medium';
font-weight: 500;
width: 443px;

h2{
    color: #4F4F4F;
    font-size: 14px;
}

.eachPermissionInstance{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid rgb(230, 230, 230);
    margin-bottom: .8rem;
    & > div{

    }
    & .permission_name{
        font-size: 1rem;
    }
    .permission_count{
        font-size: 12px;
        font-weight: 500;
        padding: 6px;
        border-radius: 5px;
        background-color: #F4F4F4;

    }
}
`


export const RolesEditMainContainer = styled.div`
padding: 1rem;
border: 1px solid #E6E6E6;
border-radius: 8px;
width:600px;

h2{
    color: #0B0A0A;
    font-size: 1.1rem;
}

`


export const RolescheckMark = styled.div`



/* The container */
.radio_container {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* border: 1px solid red; */
  }
  
  /* Hide the browser's default radio button */
  .radio_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  
  /* This is small size of the radio */
  /* Create a custom radio button */
  .radio_checkmark__sm {
    position: absolute;
    top: -8px;
    left: 0;
    height: 17px;
    width: 17px;
    background-color: #37B6AA;
    /* border-radius: 8px; */
    border: 1.4px solid #E6E6E6;
    border-radius: 5px;



  }
  
  /* On mouse-over, add a grey background color */
  .radio_container:hover input ~ .radio_checkmark__sm {
    background-color: #37B6AA;
  }
  
  /* When the radio button is checked, add a blue background */
  .radio_container input:checked ~ .radio_checkmark__sm {
    background-color: #37B6AA;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .radio_checkmark__sm:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .radio_container input:checked ~ .radio_checkmark__sm:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .radio_container .radio_checkmark__sm:after {
    /* top: 9px;
    left: 9px; */
    top: -9px;
    left: -1px;
    width: 18px; 
    height: 30px; 
    border-bottom: 5px solid white; 
    border-right: 5px solid white; 
    transform: rotate(45deg) scale(.3);   
    
    /* background: #017da3; */
  }
  
  

  .checkmark {
    display: inline-block;
    position: relative;

    width: 18px; 
    height: 30px; 
    border-bottom: 10px solid green; 
    border-right: 10px solid green; 
    transform: rotate(45deg) scale(.4);   
  }
  
  /* end Css for Cutom Radio Btn */
  `

  export const RoleBtn = styled.button`
    width: 100%;
    padding: 20px;
    border-radius: 1rem;
    border: transparent;
    background-color: #37B6AA;
    color: white;
    font-size: 1.2rem;
    font-weight: 700;
  `