import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "src/config/api";
import { parseQueryParams } from "src/helpers/parseQueryParams";

export const identityPassSlice = createApi({
    reducerPath: "identityPass",
    baseQuery:customFetchBase,
    endpoints: (builder) => ({
        manualCheck: builder.query<
        any, 
        {
            page_size: number;
            page: number;
            search?: string;
            status?: string;
            start_date?: string;
            end_date?: string;
          }
        >({
            query: (params) => ({
                method: "GET",
                url:"https://api.prembly.com/identitypass/verification/admin/manual-check",
                params: parseQueryParams(params)
            }),
        }),
    })
});

export const {
    useManualCheckQuery,
} = identityPassSlice