import { ReactElement } from "react";
import {
  CardWrapper,
  CardContainer,
  CardWrapperCount,
  CardWrapperLabel,
} from "src/components/Card/styled.card";

type Props = {
  count: number | string | ReactElement;
  label: string;
  width?: number | string;
  Icon: any;
};

const Card = ({ count, label, Icon, width }: Props) => {
  return (
    <CardWrapper width={width}>
      <CardContainer>
        <CardWrapperCount>{count}</CardWrapperCount>
        <CardWrapperLabel>{label}</CardWrapperLabel>
      </CardContainer>
      <Icon />
    </CardWrapper>
  );
};

export default Card;
