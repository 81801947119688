import { FunctionComponent, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { AUTH_PATHS } from "src/constant/path";

const SigninPage = lazy(() => import("src/modules/Auth/pages/Siginin"));
const SignupPage = lazy(() => import("src/modules/Auth/pages/Signup"));

const AuthRoute: FunctionComponent<Record<string, never>> = () => {
  return (
    <Routes>
      <Route path="/">
        <Route path="" element={<Navigate to="signin" replace />} />
        <Route path={AUTH_PATHS.SIGNIN} element={<SigninPage />} />
        <Route path={AUTH_PATHS.CREATE_PASSWORD} element={<SignupPage />} />
      </Route>
      <Route
        path="*"
        element={
          <div
            style={{
              width: "50%",
              margin: "5em auto",
              textAlign: "center",
            }}
          >
            <p>Oopppss, you seem to be lost</p>
          </div>
        }
      />
    </Routes>
  );
};

export default AuthRoute;
