import {
  ChangeEvent,
  createContext,
  Dispatch,
  FunctionComponent,
  ReactElement,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";

import { IUser_Data } from "src/interface/Merchants";
import { useSubscriptionCardsQuery } from "src/modules/Admin/services/identityRadarSlice";

// import { useUsersQuery } from "src/modules/Admin/services/merchantSlice";

export const SubscriptionCardsContext = createContext<{
  response: IUser_Data[];
  handlePageClick: (e: { selected: number }) => void;
  pageCount: number;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  viewPage: string;
  isLoading: boolean;
  isFetching: boolean;
  handleCloseModal: () => void;
  handleOpenNewUserModal: () => void;
  isAddNewUser: boolean;
  setIsAddNewUser: Dispatch<SetStateAction<boolean>>;
}>(null);

type Props = {
  children: ReactElement;
};

const SubscriptionCardsProvider: FunctionComponent<Props> = ({ children }) => {
  const [viewPage, setViewPage] = useState<any>(10);
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [response, setResponse] = useState<IUser_Data[]>();
  const [isAddNewUser, setIsAddNewUser] = useState<boolean>(false);
  const { organisationId } = useParams();

  const { data, isLoading, isFetching } = useSubscriptionCardsQuery({
    organisationId: organisationId,
  });

  console.log(pageNumber);

  console.log(organisationId, "pcd", data);

  useEffect(() => {
    if (data) {
      setResponse(data?.data);
      setPageCount(Math.ceil(data?.count / viewPage));
    }
  }, [data]);

  const handlePageClick = (event: { selected: number }) => {
    setPageNumber(event.selected);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setViewPage(Number(e.target.value));
  };

  const handleOpenNewUserModal = () => {
    setIsAddNewUser(!isAddNewUser);
  };

  const handleCloseModal = () => {
    setIsAddNewUser(!isAddNewUser);
  };

  const contextValue = useMemo(
    () => ({
      handlePageClick,
      response,
      pageCount,
      handleChange,
      viewPage,
      isLoading,
      isFetching,
      handleOpenNewUserModal,
      isAddNewUser,
      setIsAddNewUser,
      handleCloseModal,
    }),
    [response, handlePageClick, viewPage]
  );

  return (
    <SubscriptionCardsContext.Provider value={contextValue}>
      {children}
    </SubscriptionCardsContext.Provider>
  );
};

export default SubscriptionCardsProvider;
