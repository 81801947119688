import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "src/config/api";


export const rolesAndPermissionSlice= createApi({
    reducerPath:'rolesAndPermission',
    baseQuery:customFetchBase,
    tagTypes:[
        'rolesAndPermission'
    ],
    endpoints:(builder)=>({
        usersAndRoles:builder.query<any,void>({
            query:()=>({
                method:'GET',
                url:`/roles/admin-roles/all`,
            }),
            providesTags:['rolesAndPermission']
        }),
        assignUserToRole:builder.mutation({
            query:({role,userid})=>({
                method:'POST',
                url:`/roles/admin-assign-roles/${userid}`,
                body:{role}
            }),
        }),
        allRolesAndPermissions:builder.query<any,any>({
            query:()=>({
                method:'GET',
                url:'/roles/admin-roles/all'
            }),
            providesTags:['rolesAndPermission']
        }),
        singleRolesAndPermissions:builder.query<any,{slug:string}>({
            query:({slug})=>({
                method:'GET',
                url:`/roles/admin-roles/${slug}`
            }),
            providesTags:['rolesAndPermission']
        }),
        createRole:builder.mutation<any,
        {
          data: any;
        }>({
            query:({data})=>({
                method:'POST',
                url:'/roles/admin-roles/create',
                body:data
                // params: parseQueryParams(params),

            }),
            invalidatesTags:['rolesAndPermission']
        }),
        singleRolesAndPermissionsUpdate:builder.mutation<any,{slug:string,data:any}>({
            query:({slug,data})=>({
                method:'PUT',
                url:`/roles/admin-roles/${slug}`,
                body:data
            }),
            invalidatesTags:['rolesAndPermission']
        }),
    })
})


export const {
    useUsersAndRolesQuery,
    useAssignUserToRoleMutation,
    useAllRolesAndPermissionsQuery,
    useSingleRolesAndPermissionsQuery,
    useCreateRoleMutation,
    useSingleRolesAndPermissionsUpdateMutation
} = rolesAndPermissionSlice