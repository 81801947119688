import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const authSlice = createApi({
  reducerPath: "auth",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    login: builder.mutation<
      any,
      {
        ip: string;
        email: string;
        password: string;
      }
    >({
      query: (data) => ({
        method: "POST",
        url: "/auth/login",
        body: { ...data },
      }),
    }),
    createPassword: builder.mutation<
      any,
      {
        key: string;
        email: string;
        password1: string;
        password2: string;
      }
    >({
      query: (data) => ({
        method: "POST",
        url: "/account/invite/accept",
        body: { ...data },
      }),
    }),
  }),
});

export const { useLoginMutation, useCreatePasswordMutation } = authSlice;
