import { pxToRem } from "src/helpers/formatFont";
import styled from "styled-components";

export const StyledRefereeWrapper = styled.div``;

export const StyledRefereeHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e95470;
  padding: 0.8em 0px;
  margin-top: 1em;
  margin-bottom: 1em;
  p {
    margin-bottom: 0px;
  }
`;

export const StyledRefereeSearch = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 50%;
`;

export const StyledRefereeAction = styled.div`
  display: flex;
  gap: 10px;
  width: 50%;
  justify-content: flex-end;
`;

export const StyledRefereeFilter = styled.div`
  position: relative;
  width: 50%;
  text-align: end;
  p {
    font-size: ${pxToRem(12)};
    font-style: normal;
    font-weight: 500;
  }
`;

export const DropdownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.8em;
  p {
    border-bottom: 1px solid #e95470;
    margin: 0px;
    padding-bottom: 0.5em;
    font-size: ${pxToRem(14)};
    font-style: normal;
    font-weight: 500;
    color: #1d2939;
  }
`;

export const DateFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  & input {
    width: 100%;
    padding: 0.2em 1em;
  }
`;

export const ReferralUserExport = styled.div`
  position: relative;
  width: auto;
  text-align: end;
  p {
    font-size: ${pxToRem(12)};
    font-style: normal;
    font-weight: 500;
    padding: 0px;
    margin-top: 10px;
  }
`;

export const StyledReferalListWrapper = styled.div``;

export const StyledTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  & ul {
    list-style-type: none;
    display: flex;
    gap: 10px;
    & li {
      border: 1px solid #ebebf2;
      padding: 0.6em 1em;
    }
  }
  & .prev {
    & a {
      text-decoration: none;
      background: #ffffff;
      border-radius: 2px;
      color: #2d4875;
      font-weight: 400;
      font-size: 14px;
    }
  }
  & .next {
    & a {
      text-decoration: none;
      background: #ffffff;
      border-radius: 2px;
      font-weight: 400;
      color: #2d4875;
      font-size: 14px;
    }
  }
  & .active {
    background: #ebebf2;
  }
`;

export const ViewResult = styled.div`
  width: 20%;
  & * {
    text-align: center;
  }
  & .form-control {
    padding-right: 0.75rem !important;
  }
`;

export const EditUserModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    border-bottom: 1px solid #e95470;
    margin: 0px;
    padding-bottom: 0.5em;
    font-size: ${pxToRem(14)};
    font-style: normal;
    font-weight: 500;
    color: #1d2939;
  }
`;

export const ViewEarningHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e95470;
  padding: 0.8em 0px;
  margin-top: 1em;
  margin-bottom: 1em;
  p {
    margin-bottom: 0px;
  }
`;

export const ViewEarningUserSearch = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 50%;
`;

export const ViewEarningUserAction = styled.div`
  display: flex;
  gap: 10px;
  width: 50%;
  justify-content: flex-end;
`;

export const ViewEarningUserFilter = styled.div`
  position: relative;
  width: 50%;
  text-align: end;
  p {
    font-size: ${pxToRem(12)};
    font-style: normal;
    font-weight: 500;
  }
`;

export const RefereeWrapper = styled.div`
  & th {
    font-size: ${pxToRem(14)} !important;
  }
  & td {
    font-size: ${pxToRem(12)} !important;
  }
  button {
    background-color: #e9f0fc;
    border: none;
    &:hover {
      background-color: #e9f0fc;
    }
  }
`;

export const ReferralWrapper = styled.div`
  & th {
    font-size: ${pxToRem(14)} !important;
  }
  & td {
    font-size: ${pxToRem(12)} !important;
  }
`;
