import { createApi } from "@reduxjs/toolkit/query/react";

import customFetchBase from "src/config/api";

import { parseQueryParams } from "src/helpers/parseQueryParams";


export const identityRadarSlice = createApi({
  reducerPath: "identityRadar",
  baseQuery: customFetchBase,
  tagTypes: ["subscriptions", "customers", 'summary'],
  endpoints: (builder) => ({
    subscriptions: builder.query<
      any,
      {
        page_size: number;
        page: number;
        search?: string;
        status?: string;
        start_date?: string;
        end_date?: string;
      }
    >({
      query: (params) => ({
        method: "GET",
        url: `https://api.prembly.com/radar/api/v1/radar/subscriptions`,
        params: parseQueryParams(params),
      }),
      providesTags: ["subscriptions"],
    }),
    organisationDetail: builder.query({
      query:({organisationId}) => ({
        method: "GET",
        url: `https://api.prembly.com/radar/api/v1/radar/organisations/${organisationId}`,
      })
    }),
    organisationSubscriptions: builder.query({
      query:({organisationId}) => ({
        method: "GET",
        url: `https://api.prembly.com/radar/api/v1/radar/organisations/${organisationId}/subscriptions`,
      })
    }),
    subscription: builder.query({
      query:({subscriptionId}) => ({
        method: "GET",
        url: `https://api.prembly.com/radar/api/v1/radar/subscriptions/${subscriptionId}`,
      })
    }),
    subscriptionCards: builder.query({
      query: ({organisationId}) =>  ({
        method: 'GET',
        url: `https://api.prembly.com/radar/api/v1/radar/organisations/${organisationId}/cards`
      })
    }),
    customers: builder.query<
    any,
    {
      page_size: number;
      page: number;
      search?: string;
      status?: string;
      start_date?: string;
      end_date?: string;
    }
  >({
    query: (params) => ({
      method: "GET",
      url: `https://api.prembly.com/radar/api/v1/radar/organisations`,
      params: parseQueryParams(params),
    }),
    providesTags: ["customers"],
  }),
  customerSummary: builder.query<
    any,
    void
  >({
    query: () => ({
      method: "GET",
      url: `https://api.prembly.com/radar/api/v1/radar/organisations/overview`,
    }),
    providesTags: ["summary"],
  }),
  customerOverview: builder.query<any, any>({
    query: ({organisationId, ...param}) => ({
      method: "GET",
      url: `https://api.prembly.com/radar/api/v1/radar/organisations/${organisationId}/overview`,
      params: parseQueryParams(param)
    })
  }),
  }),
});

export const {
  useSubscriptionsQuery,
  useOrganisationSubscriptionsQuery,
  useSubscriptionQuery,
  useSubscriptionCardsQuery,
  useCustomersQuery,
  useCustomerSummaryQuery,
  useCustomerOverviewQuery,
  useOrganisationDetailQuery
} = identityRadarSlice;
