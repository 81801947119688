import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "src/config/api";
// import { parseQueryParams } from "src/helpers/parseQueryParams";
// import { ISummary } from "src/interface/Merchants";


export const  auditLogsslice = createApi({
    reducerPath:'auditlogs',
    baseQuery:customFetchBase,
    tagTypes:[
        'audlogs'
    ],
    endpoints:(builder)=>({
        audlogs:builder.query<any,
        {
          date: string | number;
        }>({
            query:({date})=>({
                method:'GET',
                url:`/logs/audit_logs/${date}`,
                // params: parseQueryParams(params),

            }),
            providesTags:['audlogs']
        })
    })
})


export const {
    useAudlogsQuery,
    useLazyAudlogsQuery
} = auditLogsslice