import {
  FunctionComponent,
  ReactElement,
  SVGProps,
  useContext,
  useEffect,
  useState,
} from "react";
import { DropdownHeader, TopSummaryCard } from "./styled.background";
import { BackgroundCheckRequestContext } from "src/context/BackgroundCheck/request";
import { format } from "date-fns";
import {
  Button,
  Card,
  CardSkeleton,
  Dropdown,
  Input,
  Modal,
  Notification,
} from "src/components";
import { NumericFormat } from "react-number-format";

import { ReactComponent as UserSummaryIcon } from "src/assets/images/users_summary_card.svg";
import {
  MerchantUserAction,
  MerchantUserFilter,
  MerchantUserHeader,
  MerchantUserSearch,
} from "../MerchantUsers/styled.merchantuser";
import { Table } from "react-bootstrap";

import {
  useAddVaorQAMutation,
  useAssignUserMutation,
  useGetAllUserWithIDQuery,
  useGetDssChecksQuery,
} from "../../services/backgroundCheckSlice";
import { StyledAddUserHeader } from "../../components/Merchants/Detail/Notification/styled.notification";
import OverviewMenu from "src/components/OverviewMenu";
//import { MerchantWrapper } from "../../components/BackgroundCheck/styled.backgroundCheck";

import {  useNavigate, useParams } from "react-router-dom";

const Completion_status: any = {
  PENDING: "Pending",
  ACCEPTED: "Accepted",
  VERIFIED: "Verified",
  // AWAITING: "Awaiting Action",
  AWAITING: "Waiting",
  reject: "Rejected",
  approve: "Approved",
  pending: "Pending",
  waiting: "Waiting",
  "IN PROGRESS": "In Progress",
  COMPLETED: "Completed",
  PAID: "Paid",
  UNPAID: "Unpaid",
};

const STATUS_COLOR: any = {
  PENDING: "#344054",
  pending: "#344054",
  APPROVED: "#027A48",
  approve: "#027A48",
  ACCEPTED: "#027A48",
  COMPLETED: "#027A48",
  VERIFIED: "#027A48",
  CONSENT: "#027A48",
  REJECTED: "#D92D20",
  reject: "#D92D20",
  AWAITING: "#DC6803",
  waiting: "#DC6803",
  "IN PROGRESS": "#344054",
  DELETE: "#D92D20",
  VIEW: "#0F3470",
  PAID: "#027A48",
  UNPAID: "#D92D20",
};

const STATUS_BG_COLOR: any = {
  PENDING: "#ECEDEF",
  pending: "#ECEDEF",
  APPROVED: "#9BFDD4",
  approve: "#9BFDD4",
  ACCEPTED: "#9BFDD4",
  VERIFIED: "#9BFDD4",
  COMPLETED: "#9BFDD4",
  CONSENT: "#9BFDD4",
  REJECTED: "#F7CECA",
  reject: "#F7CECA",
  AWAITING: "#FFFCF5",
  waiting: "#FFFCF5",
  "IN PROGRESS": "#ECEDEF",
  DELETE: "#F7CECA",
  VIEW: "#E9F0FC",
  PAID: "#9BFDD4",
  UNPAID: "#F7CECA",
};
const DssChecksPage: FunctionComponent<Record<never, string>> = () => {
  const [assignModal, setAssignModal] = useState<boolean>(false);
  const [vaList, setVaList] = useState<boolean>(false);
  const [firstNameVa, setFirstNameVa] = useState("");
  const [lastNameVa, setLastNameVa] = useState("");
  const [emailVa, setEmailVa] = useState("");
  const [qaList, setQaList] = useState<boolean>(false);
  const [firstNameQa, setFirstNameQa] = useState("");
  const [lastNameQa, setLastNameQa] = useState("");
  const [emailQa, setEmailQa] = useState("");
  const navigate = useNavigate()

  // const [role,setRole]= useState<'va'|'qa'>()
  const [currentUserIdToSet, setCurrentUserIdToSet] = useState<
    undefined | string
  >(null);

  const [summaryData, setSummaryData] = useState<
    {
      label: string;
      count: ReactElement;
      Icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string }>;
    }[]
  >([]);

  const [status, setStatus] = useState({ value: "", label: "" });
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [endpoint, setEndpoint] = useState();
  const [assignCheckModal, setAssignCheckModal] = useState(false);
  // const [requestId, setRequestId] = useState();
  const [message, setMessage] = useState(null);
  const [title, setTitle] = useState<"success" | "error">();
  // const [rerunModal, setRerunModal] = useState<boolean>(false);
  const _handleClose = () => {
    setIsOpenFilter(false);
  };

  const handleModule = (option: { value: string; label: string }) => {
    setStatus(option);
  };
  const [selectedRow, setSelectedRow] = useState(null);

  const _handleToggleFilterDropdown = () => {
    setIsOpenFilter(true);
  };
  const { response } = useContext(BackgroundCheckRequestContext);
  // console.log("response from DSS", response);

  useEffect(() => {
    if (response) {
      setSummaryData([
        {
          label: "Total Request",
          count: (
            <NumericFormat
              displayType="text"
              thousandSeparator=","
              value={response?.length || 0}
            />
          ),
          Icon: UserSummaryIcon,
        },
        {
          label: "Total Requests processing",
          count: (
            <NumericFormat displayType="text" thousandSeparator="," value={0} />
          ),
          Icon: UserSummaryIcon,
        },
        {
          label: "Total  Requests Processed",
          count: (
            <NumericFormat displayType="text" thousandSeparator="," value={0} />
          ),
          Icon: UserSummaryIcon,
        },
      ]);
    }
  }, [response]);

  const STATUSOPTIONS = [
    { value: "Completed", label: "Completed" },
    { value: "In Progress", label: "In Progress" },
  ];
  // const navigate = useNavigate();
  // const { pathname } = useLocation();
  const { requestId } = useParams();
  const { data, isLoading } = useGetDssChecksQuery();
  // console.log(data, "dataaaa");

  const { data: allUserData, isLoading: isLoadingUsers } =
    useGetAllUserWithIDQuery();
  const [addVAorQa, { isLoading: addingVAorQA }] = useAddVaorQAMutation();
  const [assignUser, { isLoading: loadingAssignment }] =
    useAssignUserMutation();

  const handleAddVa = async () => {
    // console.log({
    //   'Type':'qa',
    //   'userID':currentUserIdToSet,
    // });

    try {
      const resp = await addVAorQa({
        // role:role,
        user_id: currentUserIdToSet,
        name: `${firstNameVa} ${lastNameVa}`,
        email: emailVa,
      }).unwrap();
      if (resp) {
        // console.log({ resp });
        setAssignModal(false);
        setTitle("success");
        setMessage(resp.message);
        //   setTimeout(() => {
        //     window.location.reload()
        // }, 1500);
      }
    } catch (err: any) {
      //
      // console.log({ err });
    }
  };
  // console.log({addingVAorQA})
  const [showMenu, setShowMenu] = useState<boolean>(false);

  // const _handleOpenViewUser = ({
  //   checkId,
  //   checkName,
  // }: // requestChecks,
  // {
  //   checkName: string;
  //   checkId: string;
  //   // requestChecks: any;
  // }) => {
  //   navigate(
  //     `${pathname}/${checkName?.split(" ")?.join("-")}/${checkId}/details`
  //   );
  // };
  // const _handleOpenCheckUser = ({
  //   checkId,
  //   checkName,
  // }: // requestChecks,
  // {
  //   checkName: string;
  //   checkId: string;
  //   // requestChecks: any;
  // }) => {
  //   navigate(
  //     `${pathname}/${checkName
  //       ?.split(" ")
  //       ?.join("-")}/${checkId}/checks/details`
  //   );
  // };

  const _handleShow = (merchant: any) => {
    setShowMenu(!showMenu);
    setSelectedRow(merchant);
  };

  const handleCheckAssignment = async () => {
    const formData = {
      request_id: requestId,
      endpoint: endpoint,
      va: {
        first_name: firstNameVa,
        last_name: lastNameVa,
        email: emailVa,
      },
      qa: {
        first_name: firstNameQa,
        last_name: lastNameQa,
        email: emailQa,
      },
    };

    try {
      const resp = await assignUser({
        data: formData,
      }).unwrap();
      if (resp) {
        setAssignCheckModal(false);
        setTitle("success");
        setMessage(resp.detail);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    } catch (err: any) {
      //
    }
  };

  const handleCloseToast = () => {
    setMessage(null);
    setTitle(null);
  };

  const handleViewRequest = ({
    requestId,
    requestName,
    requestChecks,
  }: {
    requestName: string;
    requestId: string;
    requestChecks: any;
  }) => {
    navigate(`/background-check/requests/${requestName.split(" ").join("-")}/${requestId}`, {
      state: requestChecks,
      replace:true
    });
  };


  return (
    <div>
      <TopSummaryCard>
        {summaryData.length
          ? summaryData.map(
              (summary: {
                label: string;
                count: ReactElement;
                Icon: FunctionComponent<
                  SVGProps<SVGSVGElement> & { title?: string }
                >;
              }) => (
                <Card
                  key={summary.label}
                  label={summary.label}
                  count={summary.count}
                  Icon={summary.Icon}
                />
              )
            )
          : [0, 1, 2].map((index) => (
              <CardSkeleton height={"130"} key={index} />
            ))}
      </TopSummaryCard>
      <MerchantUserHeader>
        <MerchantUserSearch>
          <p>All Checks</p>
        </MerchantUserSearch>
        <MerchantUserAction>
          <MerchantUserFilter>
            <Button
              label="Filters"
              size="lg"
              type="button"
              variant="outline-primary"
              btnIconClassName="ri-filter-3-line"
              onClick={_handleToggleFilterDropdown}
            />

            <Dropdown isOpen={isOpenFilter} height="447px">
              <DropdownHeader>
                <p>Filters</p>
                <i
                  className="ri-close-circle-line"
                  style={{ cursor: "pointer" }}
                  onClick={_handleClose}
                />
              </DropdownHeader>

              <div className="mb-3">
                <p className="mb-1">BY STATUS </p>
                <Input
                  type="select"
                  selectPlaceholder={<p>Select Module</p>}
                  selectOptions={STATUSOPTIONS}
                  selectValue={status}
                  handleChangeOption={handleModule}
                />
              </div>

              <Button
                type="button"
                label="Clear Filter"
                onClick={() => {
                  setStatus({ label: "Default", value: "" });
                }}
                variant="link"
                size="lg"
                className="w-full mt-2 text-dark"
              />
            </Dropdown>
          </MerchantUserFilter>
        </MerchantUserAction>
      </MerchantUserHeader>
      {isLoading || isLoadingUsers ? (
        <CardSkeleton height={"500"} />
      ) : (
        <Table>
          <thead>
            <tr className="tb">
              <th>S/N</th>
              <th>Candidate Name</th>
              <th>Check Name</th>
              <th>Date</th>
              <th>Payment Status</th>
              <th>VA Name</th>
              <th>VA Status</th>
              <th>QA Name</th>
              <th>QA Status</th>
              {/* <th>QA Comment</th> */}
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data?.detail
              ?.filter((d: any) => {
                if (status?.value) {
                  return (
                    status.value.toLowerCase() === d?.status?.toLowerCase()
                  );
                }
                return true;
              })
              ?.map((d: any, index: number) => (
                <tr key={index} className="tb">
                  <td>{index + 1}</td>
                  <td>{d?.candidate_name || "--"}</td>
                  <td>{d.check_name}</td>
                  <td>
                    {/* {d.organisation	} */}
                    {format(
          new Date(d?.updated_at),
          "dd MMM yyyy. hh:mm a "
        )}
                  </td>
                  <td>
                    <span
                      style={{
                        textTransform: "capitalize",
                        color: STATUS_COLOR[d?.paid ? "PAID" : "PENDING"],
                        background:
                          STATUS_BG_COLOR[d.paid ? "PAID" : "PENDING"],
                        padding: "5px 10px",
                        borderRadius: "4px",
                      }}
                    >
                      {Completion_status[d?.paid ? "PAID" : "PENDING"] || "-"}
                      {/* {Completion_status[d.paid ? "Paid" : "Pending"]} */}
                    </span>
                  </td>
                  {/* va name */}
                  <td>
                    {d?.va_first_name} {d?.va_last_name || "--"}
                  </td>

                  {/* <td>{d.va_status ? "True" : "False"}</td> */}
                  <td>
                  <span
                        style={{
                          textTransform: "capitalize",
                          color:
                            STATUS_COLOR[
                              d?.va_status ? "VERIFIED" : "AWAITING"
                            ],
                          background:
                            STATUS_BG_COLOR[
                              d?.va_status ? "VERIFIED" : "AWAITING"
                            ],
                          padding: "5px 10px",
                          borderRadius: "4px",
                        }}
                      >
                        {Completion_status[
                          d?.va_status ? "VERIFIED" : "AWAITING"
                        ] || "-"}
                      </span>
                  </td>
                  <td>
                    {d?.qa_first_name} {d?.qa_last_name || "--"}
                  </td>
                  {/* <td>{d.qa_status ? "True" : "False"} </td> */}
                  <td>
                    {/* <span
                      style={{
                        textTransform: "capitalize",
                        color: STATUS_COLOR[d.qa_status ? "TRUE" : "FALSE"],
                        background:
                          STATUS_BG_COLOR[d.qa_status ? "TRUE" : "FALSE"],
                        padding: "5px 10px",
                        borderRadius: "4px",
                      }}
                    >
                      {Completion_status[d.qa_status ? "TRUE" : "FALSE"]}
                    </span> */}
             <span
                        style={{
                          textTransform: "capitalize",
                          color: STATUS_COLOR[d?.qa_approve],
                          background: STATUS_BG_COLOR[d?.qa_approve],
                          padding: "5px 10px",
                          borderRadius: "4px",
                        }}
                      >
                        {Completion_status[d?.qa_approve] || "-"}
                      </span>

                  </td>

                  {/* <td>{d.status}</td> */}
                  
                  <td>
                    <span
                      style={{
                        // textTransform: "capitalize",
                        color: STATUS_COLOR[d.status],
                        background: STATUS_BG_COLOR[d.status],
                        padding: "5px 10px",
                        borderRadius: "4px",
                      }}
                    >
                      {Completion_status[d.status] || "-"}
                    </span>
                  </td>

                  <td>
                    <OverviewMenu
                      selectedRow={selectedRow}
                      data={{ id: index }}
                      show={showMenu}
                      menus={[
                        // {
                        //   name: "View",
                        //   action: () =>
                        //     _handleOpenViewUser({
                        //       checkId: d.id,
                        //       checkName: d.check_name,

                        //       // requestChecks: item.first_name
                        //     }),
                        // },

                        d?.qa_approve !== "approve" && {
                          name:
                            d?.qa_email && d?.va_email
                              ? "Re-assign Check"
                              : "Assign Check",
                          action: () => {
                            setEndpoint(d.endpoint);
                            // setRequestId(d.request_id);
                            setAssignCheckModal(!assignModal);
                            setShowMenu(!showMenu);
                          },
                        },
                        {
                          name:'View Check',
                          action:()=>{
                            handleViewRequest({
                              requestId: d.request_id,
                              requestName: d.candidate_name,
                              requestChecks: {
                                criminal_history:0
                              },
                            })
                          }
                        }

                        // d?.qa_approve !== "approve" && {
                        //   name: "Rerun Check",
                        //   action: () => setRerunModal(!rerunModal),
                        // },
                      ]}
                      onClick={() => _handleShow({ id: index })}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}

      {/* modal for adding QA */}
      <Modal isOpen={assignModal} handleClose={() => setAssignModal(false)}>
        <StyledAddUserHeader>
          {/* <p>Add {role?.toLocaleUpperCase()}</p> */}
          <i
            className="ri-close-circle-line"
            onClick={() => setAssignModal(false)}
            style={{ cursor: "pointer" }}
          />
        </StyledAddUserHeader>
        <div className="pt-4">
          <div className="mb-4">
            <div
              className="d-flex justify-content-between bg-light p-2"
              onClick={() => setVaList(!vaList)}
              style={{ cursor: "pointer" }}
            >
              <h6 className="m-0">Select VA</h6>
              <i
                className={
                  !vaList ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line"
                }
              />
            </div>
            {emailVa && (
              <div
                className={`card border-0 border-bottom rounded-0 mt-1 bg-light`}
              >
                <div className="card-body m-0">
                  <div className="row">
                    <div className="col-11">
                      <p className="m-0">{`${firstNameVa} ${lastNameVa}`}</p>
                      <small className="p-0" style={{ color: "#353535" }}>
                        {emailVa}
                      </small>
                    </div>
                    <div className="col-1">
                      <i className="ri-check-double-line" />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {vaList &&
              allUserData?.data?.map((val: any, i: number) => {
                if (emailVa !== val?.email) {
                  return (
                    <div
                      className={`card border-0 border-bottom rounded-0 m-0 ${
                        emailVa === val?.email ? "bg-light" : ""
                      }`}
                      key={i}
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        className="card-body m-0"
                        onClick={() => {
                          setFirstNameVa(val?.first_name || "");
                          setLastNameVa(val?.last_name || "");
                          setEmailVa(val?.email);
                          setVaList(!vaList);
                          setCurrentUserIdToSet(val?.id);
                        }}
                      >
                        <p className="m-0">{`${val?.first_name} ${val?.last_name}`}</p>
                        <small className="p-0" style={{ color: "#353535" }}>
                          {val?.email}
                        </small>
                      </div>
                    </div>
                  );
                }
              })}
          </div>

          <Button
            label={addingVAorQA ? "Adding Va" : "Add Va"}
            onClick={handleAddVa}
            size="lg"
            variant="primary"
            type="button"
            className="w-full mt-3"
            disabled={!currentUserIdToSet}
            isSubmitting={isLoading || addingVAorQA}
          />
        </div>
      </Modal>

      <Modal
        isOpen={assignCheckModal}
        handleClose={() => setAssignCheckModal(false)}
      >
        <StyledAddUserHeader>
          <p>Assign Check</p>
          <i
            className="ri-close-circle-line"
            onClick={() => setAssignCheckModal(false)}
            style={{ cursor: "pointer" }}
          />
        </StyledAddUserHeader>
        <div className="pt-4">
          <div className="mb-4">
            <div
              className="d-flex justify-content-between bg-light p-2"
              onClick={() => setVaList(!vaList)}
              style={{ cursor: "pointer" }}
            >
              <h6 className="m-0">Select VA</h6>
              <i
                className={
                  !vaList ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line"
                }
              />
            </div>
            {emailVa && (
              <div
                className={`card border-0 border-bottom rounded-0 mt-1 bg-light`}
              >
                <div className="card-body m-0">
                  <div className="row">
                    <div className="col-11">
                      <p className="m-0">{`${firstNameVa} ${lastNameVa}`}</p>
                      <small className="p-0" style={{ color: "#353535" }}>
                        {emailVa}
                      </small>
                    </div>
                    <div className="col-1">
                      <i className="ri-check-double-line" />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {vaList &&
              allUserData?.data?.map((val: any, i: number) => {
                if (emailVa !== val?.email) {
                  return (
                    <div
                      className={`card border-0 border-bottom rounded-0 m-0 ${
                        emailVa === val?.email ? "bg-light" : ""
                      }`}
                      key={i}
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        className="card-body m-0"
                        onClick={() => {
                          setFirstNameVa(val?.first_name || "");
                          setLastNameVa(val?.last_name || "");
                          setEmailVa(val?.email);
                          setVaList(!vaList);
                        }}
                      >
                        <p className="m-0">{`${val?.first_name} ${val?.last_name}`}</p>
                        <small className="p-0" style={{ color: "#353535" }}>
                          {val?.email}
                        </small>
                      </div>
                    </div>
                  );
                }
              })}
          </div>

          <div className="mb-4">
            <div
              className="d-flex justify-content-between bg-light p-2"
              onClick={() => setQaList(!qaList)}
              style={{ cursor: "pointer" }}
            >
              <h6 className="m-0">Select QA</h6>
              <i
                className={
                  !qaList ? "ri-arrow-down-s-line" : "ri-arrow-up-s-line"
                }
              />
            </div>
            {emailQa && (
              <div
                className={`card border-0 border-bottom rounded-0 mt-1 bg-light`}
              >
                <div className="card-body m-0">
                  <div className="row">
                    <div className="col-11">
                      <p className="m-0">{`${firstNameQa} ${lastNameQa}`}</p>
                      <small className="p-0" style={{ color: "#353535" }}>
                        {emailQa}
                      </small>
                    </div>
                    <div className="col-1">
                      <i className="ri-check-double-line" />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {qaList &&
              allUserData?.data?.map((val: any, i: number) => {
                if (emailQa !== val?.email) {
                  return (
                    <div
                      className={`card border-0 border-bottom rounded-0  m-0 ${
                        emailQa === val?.email ? "bg-light" : ""
                      }`}
                      key={i}
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        className="card-body m-0"
                        onClick={() => {
                          setFirstNameQa(val?.first_name || "");
                          setLastNameQa(val?.last_name || "");
                          setEmailQa(val?.email);
                          setQaList(!qaList);
                        }}
                      >
                        <p className="m-0">{`${val?.first_name} ${val?.last_name}`}</p>
                        <small className="p-0" style={{ color: "#353535" }}>
                          {val?.email}
                        </small>
                      </div>
                    </div>
                  );
                }
              })}
          </div>

          <Button
            label="Assign Check"
            onClick={handleCheckAssignment}
            size="lg"
            variant="primary"
            type="button"
            className="w-full mt-3"
            disabled={
              !firstNameVa ||
              !lastNameVa ||
              !emailVa ||
              !firstNameQa ||
              !lastNameQa ||
              !emailQa
            }
            isSubmitting={loadingAssignment}
          />
        </div>
      </Modal>

      <Notification
        title={title}
        message={message}
        handleClose={handleCloseToast}
      />
    </div>
  );
};

export default DssChecksPage;
