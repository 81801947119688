import {
  createContext,
  FunctionComponent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";

import { useReferralEarningsQuery } from "src/modules/Admin/services/referralManagementSlice";

export const EarningContext = createContext<{
  response: any;
  isLoading: boolean;
  isFetching: boolean;
}>(null);

type Props = {
  children: ReactElement;
};

const EarningProvider: FunctionComponent<Props> = ({ children }) => {
  const [response, setResponse] = useState();
  const { businessId } = useParams();

  const { data, isLoading, isFetching } = useReferralEarningsQuery(businessId);

  useEffect(() => {
    if (data) {
      setResponse(data);
    }
  }, [data]);

  const contextValue = useMemo(
    () => ({
      response,
      isLoading,
      isFetching,
    }),
    [response, isLoading, isFetching]
  );

  return (
    <EarningContext.Provider value={contextValue}>
      {children}
    </EarningContext.Provider>
  );
};

export default EarningProvider;
