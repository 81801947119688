import { pxToRem } from "src/helpers/formatFont";
import styled from "styled-components";

export const MenuWrapper = styled.div`
  position: relative;
`;

export const MenuContainer = styled.div`
  background: #ffffff;
  padding: 5px 12px;
  border: 1px solid #d9dde3;
  border-radius: 4px;
  width: fit-content;
  position: absolute;
  left: -20px;
  top: 25px;
  z-index: 10;
  p {
    margin: 0px;
    padding: 4px 12px;
  }
`;

export const ViewUserModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    border-bottom: 1px solid #e95470;
    margin: 0px;
    padding-bottom: 0.5em;
    font-size: ${pxToRem(14)};
    font-style: normal;
    font-weight: 500;
    color: #1d2939;
  }
`;

export const ViewUserDataContact = styled.div`
  display: flex;
  align-items: center;
  // gap: 15px;
  margin: 0.1em 0px;
  p {
    margin: 0px 0px 0px 10px;
    font-style: normal;
    font-weight: 500;
    font-size: ${pxToRem(16)};
    color: #003e51;
  }
`;

export const ViewUserDataProfile = styled.div`
  text-align: center;
  margin-top: 2em;
  p {
    color: #003e51;
    font-style: normal;
    font-weight: 500;
    font-size: ${pxToRem(18)};
    margin: 0.2em 0px 1em;
  }
`;

export const ViewUserOrg = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.9em;
  p {
    margin: 0px;
    &:nth-child(1) {
      font-style: normal;
      font-weight: 400;
      font-size: ${pxToRem(12)};
      color: #000;
    }
    &:nth-child(2) {
      font-style: normal;
      font-weight: 500;
      font-size: ${pxToRem(18)};
      color: #003e51;
    }
  }
`;

export const DeactivateUserWrapper = styled.div`
  text-align: center;
  p {
    font-style: normal;
    font-weight: 500;
    font-size: ${pxToRem(18)};
  }
  button {
    margin: 5px 0px;
  }
`;

export const StyledTable = styled.div`
  & .sc-iUXMdM {
    &:nth-last-child(1) {
      text-align: end;
    }
  }
`;

export const StyledTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  & ul {
    list-style-type: none;
    display: flex;
    gap: 10px;
    & li {
      border: 1px solid #ebebf2;
      padding: 0.6em 1em;
    }
  }
  & .prev {
    & a {
      text-decoration: none;
      background: #ffffff;
      border-radius: 2px;
      color: #2d4875;
      font-weight: 400;
      font-size: 14px;
    }
  }
  & .next {
    & a {
      text-decoration: none;
      background: #ffffff;
      border-radius: 2px;
      font-weight: 400;
      color: #2d4875;
      font-size: 14px;
    }
  }
  & .active {
    background: #ebebf2;
  }
`;

export const ViewResult = styled.div`
  width: 20%;
  & * {
    text-align: center;
  }
  & .form-control {
    padding-right: 0.75rem !important;
  }
`;

export const MerchantWrapper = styled.div`
  & th {
    font-size: ${pxToRem(14)} !important;
  }
  & td {
    font-size: ${pxToRem(12)} !important;
  }
`;
