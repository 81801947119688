import { createApi } from "@reduxjs/toolkit/query/react";

import customFetchBase from "src/config/api";

import { parseQueryParams } from "src/helpers/parseQueryParams";

import {
  IExportDownload,
  IMerchantUserSummary,
  IMerchantUsers,
  IUpdateStatus,
} from "src/interface/MerchanttUsers";

export const merchantUsersSlice = createApi({
  reducerPath: "merchantUser",
  baseQuery: customFetchBase,
  tagTypes: ["merchantUsers"],
  endpoints: (builder) => ({
    merchantUserSummary: builder.query<IMerchantUserSummary, void>({
      query: () => ({
        method: "GET",
        url: "/overview/users",
      }),
    }),
    merchantUsers: builder.query<
      IMerchantUsers,
      {
        view: number;
        page: number;
        search?: string;
        status?: string;
        start_date?: string;
        end_date?: string;
      }
    >({
      query: (params) => ({
        method: "GET",
        url: `/merchants/users`,
        params: parseQueryParams(params),
      }),
      providesTags: ["merchantUsers"],
    }),
    updateStatus: builder.mutation<
      IUpdateStatus,
      {
        user_id: string;
        action: "enable" | "disable";
      }
    >({
      query: (data) => ({
        method: "POST",
        url: "/merchants/users/status",
        body: { ...data },
      }),
      invalidatesTags: ["merchantUsers"],
    }),
    exportDownload: builder.mutation<
      IExportDownload,
      { export_type: "pdf" | "excel" }
    >({
      query: (data) => ({
        url: "/merchants/users/export",
        method: "POST",
        body: { ...data },
      }),
    }),
    filterUsers: builder.mutation<any, any>({
      query: (data) => ({
        method: "POST",
        url: "/merchants/users/filter",
        body: { ...data },
      }),
    }),
  }),
});

export const {
  useMerchantUserSummaryQuery,
  useMerchantUsersQuery,
  useUpdateStatusMutation,
  useExportDownloadMutation,
  useFilterUsersMutation,
} = merchantUsersSlice;
