import styled from "styled-components";
// import { pxToRem } from "src/helpers/formatFont";



export const ViewDetailsBtn = styled.div`
    background-color:white;
    border:1px solid #E6E6E6;
    border-radius:8px;
    display:flex;
    gap:8px;
    align-items: center;
    justify-content: center;
    width: 148px;
    padding:0 .3rem;
    cursor: pointer;
`
export const TrDetailsStyle = styled.div`
    position: absolute;
    bottom: 0;
    left: 30px;
    width: 100%;
    font-family: '--general-sans-medium';
    padding-bottom: 10px;

    & p {
    color:#0B0A0A;
    /* font-size: .9rem; */
    /* padding: 1rem 0; */
    font-size: .9rem;

}

.tr_details_btn{
    border: 1px solid #E6E6E6;
    padding: .4rem .8rem;
    font-size: .8rem;
    border-radius: 6px;
}
.tr_details_dot{
width: 4px;
height: 4px;
background-color: #0B0A0A;

}
.tr_details_cotainer_option{
    display: flex;
    align-items: center;
    gap: 6px;
}
`