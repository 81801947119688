import styled from "styled-components";

export const StyledAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #9bfdd4;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: #027a48;
`;
