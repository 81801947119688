import styled from "styled-components";

import { pxToRem } from "src/helpers/formatFont";

export const TopnavWrapper = styled.div`
  border-bottom: 1px solid #c0c9d8;
  padding: 20px 40px;
`;

export const TopnavHeader = styled.p`
  text-transform: capitalize;
  font-style: normal;
  font-weight: 500;
  font-size: ${pxToRem(14)};
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0;
  a {
    text-decoration: none;
    color: inherit;
  }
  p {
    margin: 0px !important;
    text-transform: capitalize !important;
  }
`;
