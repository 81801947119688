import { createApi } from "@reduxjs/toolkit/query/react";

import customFetchBase from "src/config/api";

export const premblyCertifcateSlice = createApi({
  reducerPath: "premblyCertifcate",
  baseQuery: customFetchBase,
  tagTypes: ["premblyCertificates", "certificates", "compliance"],
  endpoints: (builder) => ({
    certificates: builder.query<any, void>({
      query: () => ({
        method: "GET",
        url: "/compliance/certificate",
      }),
      providesTags: ["certificates"],
    }),
    overview: builder.query<any, void>({
      query: () => ({
        method: "GET",
        url: "/compliance/certificate/overview",
      }),
    }),
    addNewCertificate: builder.mutation({
      query: ({ data }) => ({
        method: "POST",
        url: `/compliance/certificate`,
        body: data,
      }),
      invalidatesTags: ["certificates"],
    }),
    removeCertificate: builder.mutation({
      query: ({ data }) => ({
        method: "DELETE",
        url: `/compliance/certificate`,
        body: data,
      }),
      invalidatesTags: ["certificates"],
    }),
  }),
});

export const {
  useCertificatesQuery,
  useOverviewQuery,
  useRemoveCertificateMutation,
  useAddNewCertificateMutation,
} = premblyCertifcateSlice;
