import { StyledAvatarWrapper } from "src/components/Avatar/styled.avatar";
import { useSelector } from "react-redux";

import { RootState } from "src/store";

const Avatar = () => {
  const { user } = useSelector((state: RootState) => state.account);

  return (
    <StyledAvatarWrapper>
      <p>{`${user?.firstname?.charAt(0)}${user?.lastname?.charAt(0)}`}</p>
    </StyledAvatarWrapper>
  );
};

export default Avatar;
