import { Suspense, FunctionComponent } from "react";
import {
  Routes as BrowserRoutes,
  BrowserRouter,
  Route,
} from "react-router-dom";

import AppLoader from "src/components/Loader";

import ErrorBoundary from "src/ErrorBoundary";

import { BASE_ROUTES } from "src/constant/baseRoute";

import PrivateRoute from "src/Routes/PrivateRoute";

import AdminNotificationProvider from "src/context/Notification";

const Routes: FunctionComponent<Record<string, never>> = () => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<AppLoader />}>
        <BrowserRouter basename="/">
          <BrowserRoutes>
            {BASE_ROUTES.map(({ useAuth, path, Component, Layout }) => (
              <Route
                path={path}
                key={path}
                element={
                  useAuth && Layout ? (
                    <PrivateRoute>
                      <AdminNotificationProvider>
                        <Layout>
                          <Component />
                        </Layout>
                      </AdminNotificationProvider>
                    </PrivateRoute>
                  ) : (
                    <Component />
                  )
                }
              />
            ))}
          </BrowserRoutes>
        </BrowserRouter>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Routes;
