import { Link } from "react-router-dom";
import { ReactComponent as CaretDownIcon } from "src/assets/images/Dropdown.svg";
import { ReactComponent as CaretUpIcon } from "src/assets/images/dropdown_up.svg";
import { ReactComponent as LogoutIcon } from "src/assets/images/logoutIcon.svg";
import { SIDENAVS } from "src/constant/sidenav";
import { UserCard } from "src/components";
import {
  SidenavWrapper,
  SidenavContainer,
  SidenavMenu,
  SidenavChildWrapper,
  SidenavChildMenu,
  SidenavItem,
  StyledLogout,
  NotificationCount,
} from "src/components/Sidenav/styled.sidenav";
import { AdminNotificationContext } from "src/context/Notification";
import { useContext } from "react";

type Props = {
  pathname: string[];
};

const Sidenav = ({ pathname }: Props) => {
  const { response, isLoading } = useContext(AdminNotificationContext);

  const isActive = (path: string) => pathname.includes(path);

  const renderDropdownIcon = (menu: any) => {
    if (menu.dropdown) {
      return isActive(menu.path.split("/")[1]) ? (
        <CaretUpIcon />
      ) : (
        <CaretDownIcon />
      );
    }
    return null;
  };

  const renderChildLinks = (menu: any) => {
    if (menu.dropdown && isActive(menu.path.split("/")[1])) {
      return (
        <SidenavChildWrapper>
          {menu.children?.map((childMenu: any) => (
            <Link to={childMenu.path} key={childMenu.path}>
              <SidenavChildMenu>{childMenu.name}</SidenavChildMenu>
            </Link>
          ))}
        </SidenavChildWrapper>
      );
    }
    return null;
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <SidenavWrapper>
      <UserCard />
      <SidenavContainer>
        {SIDENAVS.map((menu: any) => (
          <SidenavItem key={menu.path}>
            <Link
              to={menu.path}
              key={menu.path}
              className={
                isActive(menu.path.split("/")[1]) ? "active" : "inactive"
              }
            >
              <SidenavMenu>
                <img src={menu.icon} alt={`${menu.name} icon`} />
                {menu.name}{" "}
                {menu.name === "Notification" && !isLoading && (
                  <NotificationCount>{response?.unread}</NotificationCount>
                )}
              </SidenavMenu>
              {renderDropdownIcon(menu)}
            </Link>
            {renderChildLinks(menu)}
          </SidenavItem>
        ))}
      </SidenavContainer>
      <StyledLogout>
        <LogoutIcon />
        <p
          style={{
            color: "white",
            margin: "0px",
            padding: "0px",
            cursor: "pointer",
          }}
          onClick={handleLogout}
        >
          Logout
        </p>
      </StyledLogout>
    </SidenavWrapper>
  );
};

export default Sidenav;
