import { Button,
  //  DateRange,
   Dropdown, Input } from "src/components"
import { DropdownHeader, MerchantUserAction, MerchantUserFilter, MerchantUserHeader, MerchantUserSearch } from "../MerchantUsers/styled.merchantuser"
// import { MerchantUserExport } from "../Merchants/styled.merchants"
import { useState,
  // ChangeEvent 
} from "react"
import { Table } from "react-bootstrap"
import eyeIcon from '../../../../assets/images/eye.svg'
import eyeSlash from '../../../../assets/images/eye-slash.svg'
import {  TrDetailsStyle, ViewDetailsBtn } from "./styled.AuditLogs"
import { useAudlogsQuery } from "../../services/AuditLogsSlice"
import { format } from "date-fns/esm"
import { MerchantWrapper } from "../../components/MerchantUsers/styled.merchantusers"


const AuditLogsIndexPage = ()=>{
    const [isOpenFilter,setIsOpenFilter] = useState(false);
    const [startDate, setStartDate] = useState<null|string>(null);
    // const [endDate, setEndDate] = useState(null);
    // isOpenExport,
    const [,setIsOpenExport] = useState(false)
    // const handleDate = (date: Date, type: string) => {
    //     if (type === "start") {
    //       setStartDate(date);
    //     } else if (type === "end") {
    //       setEndDate(date);
    //     }
    //   };
    // const [searchTerm,setSearchTerm] = useState('');
    // const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    //     setSearchTerm(e.target.value);
    //   };
      const _handleToggleFilterDropdown = () => {
        setIsOpenFilter(true);
        setIsOpenExport(false);
      };
      const _handleClose = () => {
        setIsOpenFilter(false);
        setIsOpenExport(false);
      };

      const MODULETYPEOptions =[
        {value:'Merchants',label:'Merchants'},
        {value:'User',label:'User'},
        {value:'Finance',label:'Finance'},
      ]
      const [module,setModule] = useState<{value:string,label:string}>();
      const handleModule = (option:{value:string,label:string})=>{
        setModule(option)
      }


      const ActionOptions =[
        {value:'Sign In',label:'Sign In'},
        {value:'Sign Out',label:'Sign Out'},
        {value:'Invite User',label:'Invite User'},
        {value:'Assign Profile',label:'Assign Profile'},
        {value:'Approve Merchant',label:'Approve Merchant'},
        {value:'Assign User Role',label:'Assign User Role'},
      ]
      const [actionValue,setActionValue] = useState<{value:string,label:string}>();
      const handleSetActionValue = (option:{value:string,label:string})=>{
        setActionValue(option)
      }
      const handleClearFilter =()=>{
        setActionValue({'label':'All',value:''})
        setModule({'label':'All',value:''})
        setStartDate('')
      }
      const getTodayDate =()=>{
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); 
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`
      }

      const { data,
        // isLoading, isFetching, error,
      } = useAudlogsQuery({'date':startDate||getTodayDate()});
    //   {
    //     view: viewPage,
    //     page: searchTerm ? 1 : pageNumber + 1,
    //     search: debouncedValue,
    //     start_date: selectedStartDate,
    //     end_date: selectedEndDate,
    //     business_name: selectedBusinessName,
    //     business_email: selectedBusinessEmail,
    //     status: selectedKybStatus,
    //   }
    return (
        <div>

<MerchantUserHeader>
        <MerchantUserSearch>
          <p>Audit Logs</p>
          {/* <Input
            placeholder="Search Audit Logs"
            type="search"
            name="reference"
            onChange={handleSearch}
            value={searchTerm}
            inputAdornment
          /> */}
        </MerchantUserSearch>
        <MerchantUserAction>
          <MerchantUserFilter>
            <Button
              label="Filters"
              size="lg"
              type="button"
              variant="outline-primary"
              btnIconClassName="ri-filter-3-line"
              onClick={_handleToggleFilterDropdown}
            />
            <Dropdown isOpen={isOpenFilter} height="447px">
              <DropdownHeader>
                <p>Filters</p>
                <i
                  className="ri-close-circle-line"
                  style={{ cursor: "pointer" }}
                  onClick={_handleClose}
                />
              </DropdownHeader>
             

              <div className="mb-3">
                <p className="mb-1">BY MODULE TYPE</p>
                <Input
                  type="select"
                  selectPlaceholder={<p>Select Module</p>}
                  selectOptions={MODULETYPEOptions}
                  selectValue={module}
                  handleChangeOption={handleModule}
                />
              </div>
              <div className="mb-3">
                <p className="mb-1">By ACTION</p>
                <Input
                  type="select"
                  selectPlaceholder={<p>Select Action status</p>}
                  selectOptions={ActionOptions}
                  selectValue={actionValue}
                  handleChangeOption={handleSetActionValue}
                />
              </div>
              <div className="mb-2">
                <p className="mb-1">Date created</p>
                {/* <DateRange
                  startDate={startDate}
                  endDate={endDate}

                  handleStartDate={(date: any) => handleDate(date, "start")}
                  handleEndDate={(date: any) => handleDate(date, "end")}
                /> */}
                 <Input
                  type="date"
                  selectPlaceholder={<p>Date created</p>}
                  onChange={e=>{
                    // console.log(e.target.value)
                    setStartDate(e.target.value)
                  }}
                  value={startDate}
               />
              </div>
              {/* <Button
                type="button"
                label="Filter"
              onClick={()=>{
                //
              }}
                
                // onClick={handleFilter}
                variant="primary"
                size="lg"
                className="w-100 mt-4"
              /> */}
              <Button
                type="button"
                label="Clear Filter"
                onClick={handleClearFilter}
                variant="link"
                size="lg"
                className="w-full mt-2 text-dark"
              />
            </Dropdown>
          </MerchantUserFilter>
          {/* {isMerchantSelected &&
          <MerchantUserExport>
            <Button
              label="Bulk Block"
              size="lg"
              type="submit"
              variant="danger"
            //   onClick={()=>setIsOpenBulkConfirm(true)}
            />
          </MerchantUserExport>
          } */}
          {/* <MerchantUserExport>
            <Button
              label="Export"
              size="lg"
              type="submit"
              variant="primary"
              btnIconClassName="ri-download-2-line"
              onClick={()=>{
              }}
              onClick={_handleToggleExportDropdown}
              disabled={
                !selectedKybStatus || !selectedPlateform || !selectedStartDate || !selectedEndDate
              }
            />
            <Dropdown
            isOpen={false}
            height="auto">
              <p className="export" 
              >
                .xlsx
              </p>
              <p 
              >
                .pdf
              </p>
            </Dropdown>
          </MerchantUserExport> */}
        </MerchantUserAction>
      </MerchantUserHeader>

      <MerchantWrapper>
      {/* audit log table */}
      <Table>
        <thead>
          <tr>
            {/* <th>
                  {merchantIds?.includes(item?.id) ?
                    <i className="ri-checkbox-line ri-xl" onClick={()=> removeMerchant(item?.id)} style={{cursor:"pointer"}}/>
                    :
                    <i className="ri-checkbox-blank-line ri-xl" onClick={()=> addMerchant(item?.id)} style={{cursor:"pointer"}}/>
                  }
            </th> */}
            <th>S/N</th>
            <th>USERNAME</th>
            <th>MODULE</th>
            <th>ACTION</th>
            <th>DATE</th>
            <th>IP ADDRESS</th>
            <th>View Details</th>
          </tr>
        </thead>
        <tbody>
          {
            data?.data?.filter((d:any)=>{
              // console.log({module,d})
              if(module?.value ==''){
                return true
              }
              if(module){
                return module?.value.toLowerCase() == d?.module?.toLowerCase()
              }
              return true
            }).map((d:any,index:number)=>(
              <TRDetails
              key={index}
              trData={d}
              id={index}
              />
            ))
          }
            {/* <TRDetails /> */}
        </tbody>
      </Table>
        
      </MerchantWrapper>
        </div>
    )
}
export default AuditLogsIndexPage


// type TRDetailsProp ={
    
// }
const TRDetails = ({trData,id}:{trData:any,id:number})=>{
    const [open,setOpen] = useState(false);
    const handleOpen = ()=>{
        setOpen(!open)
    }
    return (
        <>
        <tr>
        <td>
          {id+1}
        </td>
        <td>
        {trData.user_name}
        </td>
        <td>
          {trData?.module	}
        {/* December 2, 2018 */}
        </td>
        <td>
          {trData?.action_type	}
        </td>
        <td>
        {format(
          new Date(trData?.created_at),
          "dd MMM yyyy. hh:mm a "
        )}
        </td>
        <td>
          {trData?.ipaddress	}
        {/* 11.2343 */}
        </td>
       <td>
       <ViewDetailsBtn onClick={handleOpen}>
            <img src={open?eyeSlash:eyeIcon} alt="" />
            <span>{open?'Hide':'View'} Details</span>
        </ViewDetailsBtn>
       </td>

        </tr>
        {
            open?
            <tr
            style={{position:'relative',height:'90px',
            // 'border':'1px solid red'
            
        }}
            
            >
                <TrDetailsStyle>
                   <p>{trData.description	} </p>
                    <div className="tr_details_cotainer_option">
                        <div className="tr_details_btn">   {trData?.action_type	}</div>
                        <div className="tr_details_dot"></div>
                        <div className="tr_details_btn">{trData?.module	}</div>
                        <div className="tr_details_dot"></div>
                        <div className="tr_details_btn">
                    {format(new Date(trData.updated_at	), "dd MMM yyyy.")}
                          </div>
                        <div className="tr_details_dot"></div>
                        <div className="tr_details_btn">
                          {/* 4:13 AM */}
                          
                    {format(new Date(trData.updated_at	), "hh:mm a")}

                        </div>
                    </div>
                </TrDetailsStyle>
            </tr>:''
        }

        </>
    )
}