import {
  ChangeEvent,
  createContext,
  FunctionComponent,
  ReactElement,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { format } from "date-fns";

import { useDebounce } from "src/hooks/useDebounce";

import { useExportDownloadMutation } from "src/modules/Admin/services/merchantUsersSlice";

import { IMerchantUser_Data } from "src/interface/MerchanttUsers";
import { useSubscriptionsQuery } from "src/modules/Admin/services/identityRadarSlice";
// import { useSubscriptionsQuery } from "src/modules/Admin/services/merchantSlice";

export const SubscriptionsContext = createContext<{
  response: IMerchantUser_Data[];
  handlePageClick: (e: { selected: number }) => void;
  pageCount: number;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  viewPage: number;
  searchTerm: string;
  handleSearch: (e: ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  isFetching: boolean;
  error: any;
  exportError: any;
  title: "success" | "error";
  fileDownload: string;
  isOpenExport: boolean;
  startDate: string;
  endDate: string;
  setIsOpenExport: any;
  setTitle: any;
  handleFilter: () => void;
  handleDate: (e: Date, type: string) => void;
  handleStatus: (selectedOption: { value: SetStateAction<string> }) => void;
  handleExport: (type: "pdf" | "excel") => Promise<void>;
  selectedStartDate: string;
  selectedEndDate: string;
  selectedStatus: string;
  handleClearFilter: () => void;
  handleSelectOption: (
    name: string,
    selectedOption: { label: string; value: string }
  ) => void;
  filter: {
    status: { label: string; value: string };
  };
}>(null);

type Props = {
  children: ReactElement;
};

const SubscriptionsProvider: FunctionComponent<Props> = ({ children }) => {
  const [viewPage, setViewPage] = useState<number>(10);
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [response, setResponse] = useState<IMerchantUser_Data[]>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [status, setStatus] = useState<string>("");
  const [fileDownload, setFileDownload] = useState("");
  const [isOpenExport, setIsOpenExport] = useState<boolean>(false);
  const [title, setTitle] = useState<"success" | "error">();
  const [filter, setFilter] = useState({
    status: { value: "", label: "" },
  });

  const debouncedValue = useDebounce<string>(searchTerm, 500);

  const { data, isLoading, isFetching, error } = useSubscriptionsQuery({
    status: selectedStatus,
    page_size: viewPage,
    page:
      searchTerm || selectedStartDate || selectedEndDate || status
        ? 1
        : pageNumber + 1,
    search: debouncedValue,
    start_date: selectedStartDate,
    end_date: selectedEndDate,
  });

  const [exportDownload, { error: exportError }] = useExportDownloadMutation();

  useEffect(() => {
    if (data) {
      setResponse(data?.results);
      setPageCount(Math.ceil(data?.metadata?.count / viewPage));
    }
  }, [data]);

  const handlePageClick = (event: { selected: number }) => {
    setPageNumber(event.selected);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setViewPage(Number(e.target.value));
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectOption = (
    name: string,
    selectedOption: { label: string; value: string }
  ) => {
    setFilter({ ...filter, [name]: selectedOption });
  };

  const handleFilter = () => {
    const start_date = startDate
      ? format(new Date(startDate), "yyyy-MM-dd")
      : null;
    const end_date = endDate ? format(new Date(endDate), "yyyy-MM-dd") : null;

    setSelectedStartDate(start_date);
    setSelectedEndDate(end_date);
    setSelectedStatus(filter.status.value);
  };

  const handleClearFilter = () => {
    setStatus(null);
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    setStartDate(null);
    setEndDate(null);
    setFilter({ status: { value: "", label: "" } });
  };

  const handleDate = (date: Date, type: string) => {
    if (type === "start") {
      setStartDate(date);
    } else if (type === "end") {
      setEndDate(date);
    }
  };

  const handleStatus = (selectedOption: { value: SetStateAction<string> }) => {
    setStatus(selectedOption.value);
  };

  const handleExport = async (type: "pdf" | "excel"): Promise<void> => {
    setIsOpenExport(!isOpenExport);
    setFileDownload("File downloading...");
    setTitle("success");
    const payload = {
      export_type: type,
      start_date: selectedStartDate,
      end_date: selectedEndDate,
      status: selectedStatus,
    };
    try {
      const {
        data: {
          test: { url },
        },
      } = await exportDownload(payload).unwrap();
      if (url) {
        setFileDownload("File downloaded");
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.download = "merchant-users.pdf";
        link.click();
      }
    } catch (error) {
      setFileDownload("An error occurred while exporting data");
      setTitle("error");
    }
  };

  const contextValue = useMemo(
    () => ({
      handlePageClick,
      response,
      pageCount,
      handleChange,
      viewPage,
      searchTerm,
      handleSearch,
      isLoading,
      isFetching,
      error,
      exportError,
      title,
      fileDownload,
      isOpenExport,
      startDate,
      endDate,
      setIsOpenExport,
      setTitle,
      handleFilter,
      handleDate,
      handleStatus,
      handleExport,
      selectedStartDate,
      selectedEndDate,
      selectedStatus,
      handleClearFilter,
      handleSelectOption,
      filter,
    }),
    [response, handlePageClick, viewPage]
  );

  return (
    <SubscriptionsContext.Provider value={contextValue}>
      {children}
    </SubscriptionsContext.Provider>
  );
};

export default SubscriptionsProvider;
