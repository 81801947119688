

type Prop =React.PropsWithChildren<{
    customColor?:{bg:string,color:string},
    colorType:'green'|'gray'|'lightBlue'|'darkGray'|'red'|'custom',

}>
const StatusDiv=({customColor,colorType='green',children}:Prop)=>{

    const colors={
        green:{
            bg:'#9BFDD4',
            color:'#027A48'
        },
        gray:{
            bg:'#ECEDEF',
            color:'#0a0b0b'
        },
        lightBlue:{
            bg:'#9BD5FD',
            color:'#0589E5'
        },
        darkGray:{
            bg:'#42526B',
            color:'#FFFFFF'
        },
        red:{
            bg:'#F7CECA',
            color:'#D92D20'
        },
        custom:customColor
    }
    return(
        <span
        style={{
          background: colors[colorType].bg,
          padding: "5px 10px",
          borderRadius: "4px",
          color: colors[colorType].color,
          display:'inline-block'
        }}
      >
        {children}
      </span>
    )
}
export default StatusDiv